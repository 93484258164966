import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Container,
  Dropdown,
  DropdownButton,
  Form,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./restaurantsModal.css";
import "./restaurantsMedia.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_ADD_UPDATE_ATTRIBUTE,
  GET_CART_LIST,
  GET_PRODUCT_ADDONS,
  GET_SAVE_CART_LOG,
  PREVENT_RESTAURANT_ID,
  PREVENT_RESTAURANT_NAME,
  SET_CART_ADDONS,
  SET_GROUP_ID,
  SHOW_MENU_BOTTOM_NAVIGATION_VISIBLE,
} from "store/Action/RasturantMenuAction";
import CloseIcon from "@mui/icons-material/Close";
import LottiLoader from "components/shared/Loader/LottiLoader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SecondLevelBlock from "./SecondLevelBlock";
import {
  checkAndReturnTotalPrice,
  renderCheckboxRequiredOpt,
  renderDropdownTitle,
  responseReturnFunction,
} from "helpers/AddonsModal/AddonsModalHocFunctions";
import { errorToast } from "utils/toastMessage";
import { useLocation, useParams } from "react-router-dom";
import Loader from "components/shared/Loader/Loader";
import ClearCartModal from "./ClearCartModal";
import moment from "moment";
import { GET_CALL_CART_API_IN_ORDER_CHECKOUT } from "store/Action/CheckoutAction";
import { floatInput } from "helpers/InputTypeControl";
import { dummyAddonsData } from "utils/constants/AttributesDummy";
import ThirdLevelBlock from "./NestedAttribute/ThirdLevelBlock";
import FourthLevelBlock from "./NestedAttribute/FourthLevelBlock";

const RestaurantsModal = ({
  show,
  onHide,
  productName,
  activeProduct,
  cartView,
  cartBasketId,
  activeMenuTitle,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  // const selectedRestaurantId = params?.id;
  //ALL SELECTORS
  const {
    ACTIVE_PRODUCT_ID,
    ADDONS_DETAILS,
    ADDONS_DETAILS_META,
    CART_ATTRIBUTE,
    GROUP_ID,
    ACTIVE_BASKET_ID,
    DEFAULT_ADDRESS,
    ASAP_ADVANCE_STATE,
    ASAP_DATE_STATE,
    ASAP_TIME_STATE,
    RECALL_CART_API_ON_ORDER_CHECKOUT_STATE,
    RE_ORDER,
    RESTAURANTS,
    SERVICE_TYPE,
    SELECTED_RESTAURANT_DATA_STATE,
    PREVENT_RESTAURANT_Obj_STATE,
  } = useSelector((store) => ({
    ACTIVE_PRODUCT_ID: store?.RasturantMenuReducer?.activeAddonProductId,
    ADDONS_DETAILS: store?.RasturantMenuReducer?.productsAddons ?? [],
    // ADDONS_DETAILS: dummyAddonsData?.data?.options ?? [],
    ADDONS_DETAILS_META: store?.RasturantMenuReducer?.productsAddonsMeta ?? {},
    CART_ATTRIBUTE: store?.RasturantMenuReducer?.cartAddons ?? [],
    GROUP_ID: store?.RasturantMenuReducer?.groupId,
    ACTIVE_BASKET_ID: store?.RasturantMenuReducer?.productBasketId,
    ASAP_DATE_STATE: store?.ResturantReducer?.asapDate,
    ASAP_TIME_STATE: store?.ResturantReducer?.timeSlot,
    SCHEDULE_ASAP: store?.ResturantReducer?.scheduleAsap,
    ASAP_ADVANCE_STATE: store?.ResturantReducer?.asapAdvance,
    DEFAULT_ADDRESS: store.ProfileReducer.defaultAddress,
    RECALL_CART_API_ON_ORDER_CHECKOUT_STATE:
      store?.CheckoutReducer?.reCallCartApiForOrderCheckout ?? false,
    RE_ORDER: store?.OrderReducer?.reOrder ?? {},
    RESTAURANTS: store.RasturantMenuReducer.menuDetails.restaurant ?? {},
    SERVICE_TYPE: store.ResturantReducer.serviceType,
    SELECTED_RESTAURANT_DATA_STATE:
      store?.ResturantReducer?.selectedRestaurantData ?? {},
    PREVENT_RESTAURANT_Obj_STATE:
      store?.RasturantMenuReducer?.restaurantPrvId ?? {},
  }));

  const selectedRestaurantId = SELECTED_RESTAURANT_DATA_STATE?.id
    ? SELECTED_RESTAURANT_DATA_STATE?.id
    : PREVENT_RESTAURANT_Obj_STATE?.id;
  // console.log()

  //ALL STATE
  const [loader, setLoader] = useState(false);
  const [showSecondLevelBlock, setShowSecondLevelBlock] = useState(false);
  const [activeSecondLevelBlock, setActiveSecondLevelBlock] = useState("");
  const [visibleSecondLevelBlock, setVisibleSecondLevelBlock] = useState(false);
  const [addonsObj, setAddonsObj] = useState({});
  const [temp, setTemp] = useState(false);
  const [errorHandlerArr, setErrorHandler] = useState([]);
  const [firstLevelErrorHandlerArr, setFirstLevelErrorHandlerArr] = useState(
    []
  );
  const [addUpdateAttributeLoader, setAddUpdateAttributeLoader] =
    useState(false);

  const [triggerSetAttributeEffect, setTriggerSetAttributeEffect] =
    useState(false);
  const [basketId, setBasketId] = useState("");
  const [showCartClearModal, setShowCartClearModal] = useState(false);
  const [clearCartMessage, setClearCartMessage] = useState("");
  const [isAnotherResOrderAddedKey, setIsAnotherResOrderAddedKey] =
    useState("");
  const [activeQtyInput, setActiveInput] = useState(false);
  // ALL STATE 3rd
  const [showThirdLevelBlock, setShowThirdLevelBlock] = useState(false);
  const [activeThirdLevelBlock, setActiveThirdLevelBlock] = useState("");
  const [visibleThirdLevelBlock, setVisibleThirdLevelBlock] = useState(false);
  const [activeNestedMode, setActiveNestedMode] = useState("firstLevel");
  // ALL STATE 4rd
  const [showFourthLevelBlock, setShowFourthLevelBlock] = useState(false);
  const [activeFourthLevelBlock, setActiveFourthLevelBlock] = useState("");
  const [visibleFourthLevelBlock, setVisibleFourthLevelBlock] = useState(false);

  useEffect(() => {
    setShowSecondLevelBlock(false);
    setActiveSecondLevelBlock("");
    setIsAnotherResOrderAddedKey("");
    setShowThirdLevelBlock(false);
    setShowFourthLevelBlock(false);
  }, []);

  useEffect(() => {
    if (
      activeMenuTitle === "restaurant-misc" ||
      activeMenuTitle === "food-dudes-misc"
    ) {
      setActiveInput(true);
    }
    if (activeProduct?.products_id) {
      setTriggerSetAttributeEffect(false);
      let params = {};
      if (cartView) {
        params = {
          productsId: activeProduct?.products_id,
          group_id: GROUP_ID ?? "",
          is_cart_view: cartView,
          customers_basket_id: cartBasketId,
        };
      } else {
        params = {
          productsId: activeProduct?.products_id,
          group_id: GROUP_ID ?? "",
        };
      }

      dispatch(
        GET_PRODUCT_ADDONS({ params }, (res, loading, error) => {
          setLoader(loading);
          // console.log("res-->", res, "dummyAddonsData", dummyAddonsData);
          if (!error) {
            setBasketId(res?.customers_basket_id);
            firstRenderAttributes(res?.customers_basket_id);
            // setBasketId(dummyAddonsData?.customers_basket_id);
            // firstRenderAttributes(dummyAddonsData?.customers_basket_id);
          }
        })
      );
    }
  }, [activeProduct?.products_id]);

  const firstRenderAttributes = (firstLoadBasketId) => {
    if (Object.keys(RE_ORDER).length !== 0) {
      if (cartView == 1 && cartBasketId) {
        setAddonsObj({
          products_id: activeProduct?.products_id,
          product_to_category_id: activeProduct?.product_to_category_id,
          attributes: [],
          customers_basket_quantity:
            activeProduct?.customers_basket_quantity ?? 1,
          group_id: GROUP_ID ?? "",
          restaurant_id:
            selectedRestaurantId == undefined
              ? RESTAURANTS?.id
              : selectedRestaurantId
              ? selectedRestaurantId
              : "",
          is_clear_old_cart: "",
          customers_basket_id: firstLoadBasketId,
          // productPrice: parseFloat(
          //   activeProduct?.products_price ||
          //     activeProduct?.products_price_sorter
          // ),
          productPrice: parseFloat(activeProduct?.products_price),
        });
      } else {
        setAddonsObj({
          products_id: activeProduct?.products_id,
          product_to_category_id: activeProduct?.product_to_category_id,
          attributes: [],
          customers_basket_quantity: 1,
          group_id: GROUP_ID ?? "",
          restaurant_id:
            selectedRestaurantId == undefined
              ? RESTAURANTS?.id
              : selectedRestaurantId
              ? selectedRestaurantId
              : "",
          is_clear_old_cart: "",
          customers_basket_id: "",
          // productPrice: parseFloat(
          //   activeProduct?.products_price ||
          //     activeProduct?.products_price_sorter
          // ),
          productPrice: parseFloat(activeProduct?.products_price),
        });
      }
      setTriggerSetAttributeEffect(true);
    } else {
      if (cartView == 1 && cartBasketId) {
        setAddonsObj({
          products_id: activeProduct?.products_id,
          product_to_category_id: activeProduct?.product_to_category_id,
          attributes: [],
          customers_basket_quantity:
            activeProduct?.customers_basket_quantity ?? 1,
          group_id: GROUP_ID ?? "",
          restaurant_id:
            selectedRestaurantId == undefined
              ? RESTAURANTS?.id
              : selectedRestaurantId
              ? selectedRestaurantId
              : "",
          is_clear_old_cart: "",
          customers_basket_id: cartBasketId,
          // productPrice: parseFloat(
          //   activeProduct?.products_price ||
          //     activeProduct?.products_price_sorter
          // ),
          productPrice: parseFloat(activeProduct?.products_price),
        });
      } else {
        setAddonsObj({
          products_id: activeProduct?.products_id,
          product_to_category_id: activeProduct?.product_to_category_id,
          attributes: [],
          customers_basket_quantity: 1,
          group_id: GROUP_ID ?? "",
          restaurant_id:
            selectedRestaurantId == undefined
              ? RESTAURANTS?.id
              : selectedRestaurantId
              ? selectedRestaurantId
              : "",
          is_clear_old_cart: "",
          customers_basket_id: "",
          // productPrice: parseFloat(
          //   activeProduct?.products_price ||
          //     activeProduct?.products_price_sorter
          // ),
          productPrice: parseFloat(activeProduct?.products_price),
        });
      }
      setTriggerSetAttributeEffect(true);
    }
  };

  const handelShowSecondLevelModiefire = (data, optVal, type, status) => {
    setActiveSecondLevelBlock(optVal);
    if (type == "check") {
      if (status == true) {
        setShowSecondLevelBlock(true);
      } else {
        setShowSecondLevelBlock(false);
      }
    }
    if (type == "radioType") {
      if (status == true) {
        setShowSecondLevelBlock(true);
      } else {
        setShowSecondLevelBlock(false);
      }
    }
    if (type == "testType") {
      if (status == true) {
        setShowSecondLevelBlock(true);
      } else {
        setShowSecondLevelBlock(false);
      }
    }
  };
  const handelShowSecondLevelModifureOnEvent = (data, optVal, type, status) => {
    setActiveSecondLevelBlock(optVal);
    if (type == "check") {
      if (status == true) {
        setShowSecondLevelBlock(true);
      } else {
        setShowSecondLevelBlock(false);
      }
    }
    if (type == "radioType") {
      if (status == true) {
        setShowSecondLevelBlock(true);
      } else {
        setShowSecondLevelBlock(false);
      }
    }
    if (type == "testType") {
      if (status == true) {
        setShowSecondLevelBlock(true);
      } else {
        setShowSecondLevelBlock(false);
      }
    }
  };

  const showSecondLevelBlockUi = (data, optVal) => {
    setActiveSecondLevelBlock({ ...optVal, options_id: data?.options_id });
    setVisibleSecondLevelBlock(!visibleSecondLevelBlock);
    setActiveNestedMode("secondLevel");
  };

  const handelChangeCheckbox = (event, option, optionValue) => {
    const result = {
      options_id: option?.options_id,
      options_values_id: optionValue?.options_values_id,
      options_values_price: optionValue?.options_values_price,
      // options_value_text: "",
      attributes_max_select: option?.attributes_max_select,
      attributes_min_select: option?.attributes_min_select,
      products_options_name: option?.products_options_name,
      free_modifier_quantity: option?.free_modifier_quantity,
      optionValues1: option?.optionValues,
      //quantity: optionValue?.quantity,
      quantity: option?.increment_choice_quantity,
      second_level_attributes: [],
    };
    if (event) {
      addonsObj?.attributes?.push(result);
      secondLevelUseEffect(optionValue?.second_level_options, result);
      setAddonsObj(addonsObj);
      handelShowSecondLevelModifureOnEvent(option, optionValue, "check", true);
      setTemp(!temp);
    } else {
      addonsObj.attributes = addonsObj?.attributes?.filter(
        (el) => el?.options_values_id !== optionValue?.options_values_id
      );
      setAddonsObj(addonsObj);
      setShowSecondLevelBlock(false);
      setTemp(!temp);
    }
  };

  const handelChangeRadio = (event, option) => {
    const dt = JSON.parse(event);

    const result = {
      options_id: option?.options_id,
      options_values_id: dt?.options_values_id,
      options_values_price: dt?.options_values_price,
      // options_value_text: "",
      attributes_max_select: option?.attributes_max_select,
      attributes_min_select: option?.attributes_min_select,
      products_options_name: option?.products_options_name,
      free_modifier_quantity: option?.free_modifier_quantity,
      quantity: dt?.quantity,
      optionValues1: option?.optionValues,
      second_level_attributes: [],
    };
    if (
      typeof addonsObj?.attributes !== "undefined" &&
      addonsObj?.attributes.some((dl) => dl.options_id == option?.options_id)
    ) {
      const pal = addonsObj.attributes.filter(
        (el) => el?.options_id !== option?.options_id
      );
      pal.push(result);
      addonsObj.attributes = pal;
      secondLevelUseEffect(dt?.second_level_options, result);
      setAddonsObj(addonsObj);
      if (
        addonsObj?.attributes?.some(
          (fg) => fg?.options_values_id == dt?.options_values_id
        )
      ) {
        handelShowSecondLevelModifureOnEvent(option, dt, "radioType", true);
      } else {
        handelShowSecondLevelModifureOnEvent(option, dt, "radioType", false);
      }
      // setShowSecondLevelBlock(false);
      setTemp(!temp);
    } else {
      addonsObj.attributes.push(result);
      secondLevelUseEffect(dt?.second_level_options, result);
      setAddonsObj(addonsObj);
      if (
        addonsObj?.attributes?.some(
          (fg) => fg?.options_values_id == dt?.options_values_id
        )
      ) {
        handelShowSecondLevelModifureOnEvent(option, dt, "radioType", true);
      } else {
        handelShowSecondLevelModifureOnEvent(option, dt, "radioType", false);
      }
      // setShowSecondLevelBlock(false);
      setTemp(!temp);
    }
  };

  const handelChangeText = (event, option, optionValue) => {
    setAddonsObj((prevState) => ({
      ...prevState,
      attributes: prevState?.attributes?.map((el) => {
        if (el.options_id == option?.options_id) {
          el.options_value_text = event.target.value;
        }
        return {
          ...el,
        };
      }),
    }));

    setTemp(!temp);
  };

  const handelChangeDropdown = (option, event) => {
    const dt = JSON.parse(event);
    const result = {
      options_id: option?.options_id,
      options_values_id: dt?.options_values_id,
      options_values_price: dt?.options_values_price,
      // options_value_text: "",
      attributes_max_select: option?.attributes_max_select,
      attributes_min_select: option?.attributes_min_select,
      products_options_name: option?.products_options_name,
      free_modifier_quantity: option?.free_modifier_quantity,
      optionValues1: option?.optionValues,
      quantity: dt?.quantity,
      second_level_attributes: [],
    };
    if (
      Object.keys(addonsObj).length &&
      addonsObj?.attributes.some((dl) => dl.options_id == option?.options_id)
    ) {
      const pal = addonsObj.attributes.filter(
        (el) => el?.options_id !== option?.options_id
      );

      pal.push(result);
      addonsObj.attributes = pal;
      setAddonsObj(addonsObj);
      setTemp(!temp);
    } else {
      addonsObj.attributes.push(result);
      setAddonsObj(addonsObj);
      setTemp(!temp);
    }
  };

  const handelChangeQty = (event, option, optionValue) => {
    if (event == "addition") {
      setAddonsObj((prevState) => ({
        ...prevState,
        attributes: prevState?.attributes?.map((el) => {
          if (el.options_values_id === optionValue.options_values_id) {
            el.quantity = el.quantity + option.increment_choice_quantity;
          }
          return {
            ...el,
          };
        }),
      }));
    } else {
      setAddonsObj((prevState) => ({
        ...prevState,
        attributes: prevState?.attributes?.map((el) => {
          if (el.options_values_id == optionValue.options_values_id) {
            el.quantity = el.quantity - option.increment_choice_quantity;
          }
          return {
            ...el,
          };
        }),
      }));
    }
  };

  useEffect(() => {
    // console.log("addonsObj On Use Effect", addonsObj);
  }, [temp]);

  useEffect(() => {
    if (triggerSetAttributeEffect == true) {
      if (
        Object.keys(addonsObj)?.length !== 0 &&
        ADDONS_DETAILS?.length !== 0
      ) {
        addonsObj.attributes = [];
        ADDONS_DETAILS?.map((el) => {
          if (el?.products_options_type == 3) {
            const defaultChecked = el?.optionValues.filter(
              (ad) => ad?.attributes_default > 0
            );

            defaultChecked?.map((dt) => {
              const resultFirstLev = {
                options_id: el?.options_id,
                options_values_id: dt?.options_values_id,
                options_values_price: dt?.options_values_price,
                attributes_max_select: el?.attributes_max_select,
                attributes_min_select: el?.attributes_min_select,
                products_options_name: el?.products_options_name,
                free_modifier_quantity: el?.free_modifier_quantity,
                quantity: dt?.attributes_default,
                optionValues1: el?.optionValues,
                second_level_attributes: [],
              };
              secondLevelUseEffect(dt?.second_level_options, resultFirstLev);
              const attributeLength = addonsObj?.attributes?.length;
              addonsObj.attributes[attributeLength] = resultFirstLev;
            });
          }
          if (el?.products_options_type == 2) {
            const defaultChecked = el?.optionValues?.find(
              (ad) => ad?.attributes_default > 0
            );
            if (defaultChecked !== undefined) {
              if (Object?.keys(defaultChecked)?.length !== 0) {
                const resultFirstLevel = {
                  options_id: el?.options_id,
                  options_values_id: defaultChecked?.options_values_id,
                  options_values_price: defaultChecked?.options_values_price,
                  attributes_max_select: el?.attributes_max_select,
                  attributes_min_select: el?.attributes_min_select,
                  products_options_name: el?.products_options_name,
                  free_modifier_quantity: el?.free_modifier_quantity,
                  quantity: defaultChecked?.attributes_default,
                  optionValues1: el?.optionValues,
                  second_level_attributes: [],
                };
                secondLevelUseEffect(
                  defaultChecked?.second_level_options,
                  resultFirstLevel
                );
                const attributeLength = addonsObj?.attributes?.length;
                addonsObj.attributes[attributeLength] = resultFirstLevel;
              }
            }
          }
          if (el?.products_options_type == 1) {
            const defaultChecked = el?.optionValues?.[0];
            if (defaultChecked != undefined) {
              if (Object?.keys(defaultChecked)?.length !== 0) {
                const resultFirstLevel = {
                  options_id: el?.options_id,
                  options_values_id: defaultChecked?.options_values_id,
                  options_values_price: defaultChecked?.options_values_price,
                  options_value_text: defaultChecked?.options_value_text,
                  attributes_max_select: el?.attributes_max_select,
                  attributes_min_select: el?.attributes_min_select,
                  products_options_name: el?.products_options_name,
                  free_modifier_quantity: el?.free_modifier_quantity,
                  quantity: defaultChecked?.attributes_default,
                  optionValues1: el?.optionValues,
                  second_level_attributes: [],
                };
                secondLevelUseEffect(
                  defaultChecked?.second_level_options,
                  resultFirstLevel
                );
                const attributeLength = addonsObj?.attributes?.length;
                addonsObj.attributes[attributeLength] = resultFirstLevel;
              }
            }
          }
          if (el?.products_options_type == 0) {
            const defaultChecked = el?.optionValues?.find(
              (ad) => ad?.attributes_default > 0
            );
            if (defaultChecked != undefined) {
              if (Object?.keys(defaultChecked)?.length !== 0) {
                const resultFirstLevel = {
                  options_id: el?.options_id,
                  options_values_id: defaultChecked?.options_values_id,
                  options_values_price: defaultChecked?.options_values_price,
                  attributes_max_select: el?.attributes_max_select,
                  attributes_min_select: el?.attributes_min_select,
                  products_options_name: el?.products_options_name,
                  free_modifier_quantity: el?.free_modifier_quantity,
                  quantity: defaultChecked?.attributes_default,
                  optionValues1: el?.optionValues,
                  second_level_attributes: [],
                };
                secondLevelUseEffect(
                  defaultChecked?.second_level_options,
                  resultFirstLevel
                );
                const attributeLength = addonsObj?.attributes?.length;
                addonsObj.attributes[attributeLength] = resultFirstLevel;
              }
            }
          }
        });
        setTemp(!temp);
      }
    }
  }, [triggerSetAttributeEffect]);

  const secondLevelUseEffect = (secLevelData, resultFirstLev) => {
    secLevelData?.map((el) => {
      if (el?.products_options_type == 3) {
        const defaultChecked = el?.optionValues.filter(
          (ad) => ad?.attributes_default > 0
        );
        defaultChecked?.map((dt) => {
          const result = {
            options_id: el?.options_id,
            options_values_id: dt?.options_values_id,
            options_values_price: dt?.options_values_price,
            // options_value_text: "",
            attributes_max_select: el?.attributes_max_select,
            attributes_min_select: el?.attributes_min_select,
            products_options_name: el?.products_options_name,
            products_options_values_name: dt?.products_options_values_name,
            quantity: dt?.quantity,
            free_modifier_quantity: el?.free_modifier_quantity,
            optionValues1: el?.optionValues,
            attributes_default: dt?.attributes_default,
            third_level_attributes: [],
          };
          thirdLevelUseEffect(
            dt?.third_level_options,
            result.third_level_attributes
          );

          resultFirstLev?.second_level_attributes?.push(result);
        });
      }
      if (el?.products_options_type == 2) {
        const defaultCheckedSecondLevel = el?.optionValues?.filter(
          (ad) => ad?.attributes_default > 0
        );
        defaultCheckedSecondLevel?.map((dt) => {
          const result = {
            options_id: el?.options_id,
            options_values_id: dt?.options_values_id,
            options_values_price: dt?.options_values_price,
            // options_value_text: "",
            attributes_max_select: el?.attributes_max_select,
            attributes_min_select: el?.attributes_min_select,
            products_options_name: el?.products_options_name,
            products_options_values_name: dt?.products_options_values_name,
            quantity: dt?.quantity,
            free_modifier_quantity: el?.free_modifier_quantity,
            optionValues1: el?.optionValues,
            attributes_default: dt?.attributes_default,
            third_level_attributes: [],
          };
          thirdLevelUseEffect(
            dt?.third_level_options,
            result.third_level_attributes
          );
          resultFirstLev?.second_level_attributes?.push(result);
        });
      }
      if (el?.products_options_type == 1) {
        const defaultChecked = el?.optionValues?.[0];
        if (defaultChecked != undefined) {
          if (Object?.keys(defaultChecked)?.length !== 0) {
            const result = {
              options_id: el?.options_id,
              options_values_id: defaultChecked?.options_values_id,
              options_values_price: defaultChecked?.options_values_price,
              options_value_text: defaultChecked?.options_value_text,
              attributes_max_select: el?.attributes_max_select,
              attributes_min_select: el?.attributes_min_select,
              products_options_name: el?.products_options_name,
              free_modifier_quantity: el?.free_modifier_quantity,
              quantity: defaultChecked?.quantity,
              optionValues1: el?.optionValues,
              attributes_default: defaultChecked?.attributes_default,
              third_level_attributes: [],
              // products_options_values_name:
              //   defaultChecked?.products_options_values_name,
            };
            resultFirstLev?.second_level_attributes?.push(result);
          }
        }
      }
      if (el?.products_options_type == 0) {
        const defaultCheckedSecondLevel = el?.optionValues?.filter(
          (ad) => ad?.attributes_default > 0
        );
        defaultCheckedSecondLevel?.map((dt) => {
          const result = {
            options_id: el?.options_id,
            options_values_id: dt?.options_values_id,
            options_values_price: dt?.options_values_price,
            attributes_max_select: el?.attributes_max_select,
            attributes_min_select: el?.attributes_min_select,
            products_options_name: el?.products_options_name,
            products_options_values_name: dt?.products_options_values_name,
            quantity: dt?.quantity,
            free_modifier_quantity: el?.free_modifier_quantity,
            optionValues1: el?.optionValues,
            attributes_default: dt?.attributes_default,
            third_level_attributes: [],
          };
          resultFirstLev?.second_level_attributes?.push(result);
        });
      }
    });
  };

  const thirdLevelUseEffect = (thirdLevelData, resultThirdLev) => {
    thirdLevelData?.map((el) => {
      if (el?.products_options_type == 3) {
        const defaultChecked = el?.optionValues.filter(
          (ad) => ad?.attributes_default > 0
        );
        defaultChecked?.map((dt) => {
          const result = {
            options_id: el?.options_id,
            options_values_id: dt?.options_values_id,
            options_values_price: dt?.options_values_price,
            // options_value_text: "",
            attributes_max_select: el?.attributes_max_select,
            attributes_min_select: el?.attributes_min_select,
            products_options_name: el?.products_options_name,
            products_options_values_name: dt?.products_options_values_name,
            quantity: dt?.quantity,
            free_modifier_quantity: el?.free_modifier_quantity,
            optionValues1: el?.optionValues,
            attributes_default: dt?.attributes_default,
            fourth_level_attributes: [],
          };
          if (
            !resultThirdLev.some(
              (it) =>
                it.options_id === result.options_id &&
                it.options_values_id === result.options_values_id
            )
          ) {
            fourthLevelUseEffect(
              dt?.fourth_level_options,
              result.fourth_level_attributes
            );
            resultThirdLev?.push(result);
          }
        });
      }
      if (el?.products_options_type == 2) {
        const defaultCheckedSecondLevel = el?.optionValues?.filter(
          (ad) => ad?.attributes_default > 0
        );
        defaultCheckedSecondLevel?.map((dt) => {
          const result = {
            options_id: el?.options_id,
            options_values_id: dt?.options_values_id,
            options_values_price: dt?.options_values_price,
            // options_value_text: "",
            attributes_max_select: el?.attributes_max_select,
            attributes_min_select: el?.attributes_min_select,
            products_options_name: el?.products_options_name,
            products_options_values_name: dt?.products_options_values_name,
            quantity: dt?.quantity,
            free_modifier_quantity: el?.free_modifier_quantity,
            optionValues1: el?.optionValues,
            attributes_default: dt?.attributes_default,
            fourth_level_attributes: [],
          };
          if (
            !resultThirdLev.some(
              (it) =>
                it.options_id === result.options_id &&
                it.options_values_id === result.options_values_id
            )
          ) {
            fourthLevelUseEffect(
              dt?.fourth_level_options,
              result.fourth_level_attributes
            );
            resultThirdLev?.push(result);
          }
        });
      }
      if (el?.products_options_type == 1) {
        const defaultChecked = el?.optionValues?.[0];
        if (defaultChecked != undefined) {
          if (Object?.keys(defaultChecked)?.length !== 0) {
            const result = {
              options_id: el?.options_id,
              options_values_id: defaultChecked?.options_values_id,
              options_values_price: defaultChecked?.options_values_price,
              options_value_text: defaultChecked?.options_value_text,
              attributes_max_select: el?.attributes_max_select,
              attributes_min_select: el?.attributes_min_select,
              products_options_name: el?.products_options_name,
              free_modifier_quantity: el?.free_modifier_quantity,
              quantity: defaultChecked?.quantity,
              optionValues1: el?.optionValues,
              attributes_default: defaultChecked?.attributes_default,
              fourth_level_attributes: [],
            };
            if (
              !resultThirdLev?.some(
                (it) =>
                  it.options_id === result.options_id &&
                  it.options_values_id === result.options_values_id
              )
            )
              resultThirdLev?.push(result);
          }
        }
      }
      if (el?.products_options_type == 0) {
        const defaultCheckedSecondLevel = el?.optionValues?.filter(
          (ad) => ad?.attributes_default > 0
        );
        defaultCheckedSecondLevel?.map((dt) => {
          const result = {
            options_id: el?.options_id,
            options_values_id: dt?.options_values_id,
            options_values_price: dt?.options_values_price,
            attributes_max_select: el?.attributes_max_select,
            attributes_min_select: el?.attributes_min_select,
            products_options_name: el?.products_options_name,
            products_options_values_name: dt?.products_options_values_name,
            quantity: dt?.quantity,
            free_modifier_quantity: el?.free_modifier_quantity,
            optionValues1: el?.optionValues,
            attributes_default: dt?.attributes_default,
            fourth_level_attributes: [],
          };
          if (
            !resultThirdLev.some(
              (it) =>
                it.options_id === result.options_id &&
                it.options_values_id === result.options_values_id
            )
          )
            resultThirdLev?.push(result);
        });
      }
    });
  };

  const fourthLevelUseEffect = (fourthLevelData, resultFourthLev) => {
    fourthLevelData?.map((el) => {
      if (el?.products_options_type == 3) {
        const defaultChecked = el?.optionValues.filter(
          (ad) => ad?.attributes_default > 0
        );
        defaultChecked?.map((dt) => {
          const result = {
            options_id: el?.options_id,
            options_values_id: dt?.options_values_id,
            options_values_price: dt?.options_values_price,
            // options_value_text: "",
            attributes_max_select: el?.attributes_max_select,
            attributes_min_select: el?.attributes_min_select,
            products_options_name: el?.products_options_name,
            products_options_values_name: dt?.products_options_values_name,
            quantity: dt?.quantity,
            free_modifier_quantity: el?.free_modifier_quantity,
            optionValues1: el?.optionValues,
            attributes_default: dt?.attributes_default,
          };
          if (
            !resultFourthLev.some(
              (it) =>
                it.options_id === result.options_id &&
                it.options_values_id === result.options_values_id
            )
          )
            resultFourthLev?.push(result);
        });
      }
      if (el?.products_options_type == 2) {
        const defaultCheckedSecondLevel = el?.optionValues?.filter(
          (ad) => ad?.attributes_default > 0
        );
        defaultCheckedSecondLevel?.map((dt) => {
          const result = {
            options_id: el?.options_id,
            options_values_id: dt?.options_values_id,
            options_values_price: dt?.options_values_price,
            // options_value_text: "",
            attributes_max_select: el?.attributes_max_select,
            attributes_min_select: el?.attributes_min_select,
            products_options_name: el?.products_options_name,
            products_options_values_name: dt?.products_options_values_name,
            quantity: dt?.quantity,
            free_modifier_quantity: el?.free_modifier_quantity,
            optionValues1: el?.optionValues,
            attributes_default: dt?.attributes_default,
          };
          if (
            !resultFourthLev.some(
              (it) =>
                it.options_id === result.options_id &&
                it.options_values_id === result.options_values_id
            )
          )
            resultFourthLev?.push(result);
        });
      }
      if (el?.products_options_type == 1) {
        const defaultChecked = el?.optionValues?.[0];
        if (defaultChecked != undefined) {
          if (Object?.keys(defaultChecked)?.length !== 0) {
            const result = {
              options_id: el?.options_id,
              options_values_id: defaultChecked?.options_values_id,
              options_values_price: defaultChecked?.options_values_price,
              options_value_text: defaultChecked?.options_value_text,
              attributes_max_select: el?.attributes_max_select,
              attributes_min_select: el?.attributes_min_select,
              products_options_name: el?.products_options_name,
              free_modifier_quantity: el?.free_modifier_quantity,
              quantity: defaultChecked?.quantity,
              optionValues1: el?.optionValues,
              attributes_default: defaultChecked?.attributes_default,
            };
            if (
              !resultFourthLev.some(
                (it) =>
                  it.options_id === result.options_id &&
                  it.options_values_id === result.options_values_id
              )
            )
              resultFourthLev?.push(result);
          }
        }
      }
      if (el?.products_options_type == 0) {
        const defaultCheckedSecondLevel = el?.optionValues?.filter(
          (ad) => ad?.attributes_default > 0
        );
        defaultCheckedSecondLevel?.map((dt) => {
          const result = {
            options_id: el?.options_id,
            options_values_id: dt?.options_values_id,
            options_values_price: dt?.options_values_price,
            attributes_max_select: el?.attributes_max_select,
            attributes_min_select: el?.attributes_min_select,
            products_options_name: el?.products_options_name,
            products_options_values_name: dt?.products_options_values_name,
            quantity: dt?.quantity,
            free_modifier_quantity: el?.free_modifier_quantity,
            optionValues1: el?.optionValues,
            attributes_default: dt?.attributes_default,
          };
          if (
            !resultFourthLev.some(
              (it) =>
                it.options_id === result.options_id &&
                it.options_values_id === result.options_values_id
            )
          )
            resultFourthLev?.push(result);
        });
      }
    });
  };

  const handelDisableCheckbox = (data, optionValue) => {
    if (data?.attributes_min_select == 1 && data?.attributes_max_select == 1) {
      const newTempId = addonsObj?.attributes?.find(
        (dy) => dy?.options_id == data?.options_id
      );

      if (newTempId) {
        const newTemp = addonsObj?.attributes?.find(
          (dy) =>
            dy?.options_id == data?.options_id &&
            dy?.options_values_id == optionValue?.options_values_id
        );

        if (newTemp) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else if (
      data?.attributes_min_select == 0 &&
      data?.attributes_max_select >= 1
    ) {
      const newTempId = addonsObj?.attributes?.filter(
        (dy) => dy?.options_id == data?.options_id
      );
      if (
        newTempId != undefined &&
        newTempId?.length < data?.attributes_max_select
      ) {
        return false;
      } else {
        const newTempOpt = newTempId?.find(
          (dy) =>
            dy?.options_id == data?.options_id &&
            dy?.options_values_id == optionValue?.options_values_id
        );

        if (newTempOpt) {
          return false;
        } else {
          return true;
        }
      }
    } else if (
      data?.attributes_min_select > 0 &&
      data?.attributes_max_select >= 1
    ) {
      const newTempId = addonsObj?.attributes?.filter(
        (dy) => dy?.options_id == data?.options_id
      );
      if (
        newTempId != undefined &&
        newTempId?.length < data?.attributes_max_select
      ) {
        return false;
      } else {
        const newTempOpt = newTempId?.find(
          (dy) =>
            dy?.options_id == data?.options_id &&
            dy?.options_values_id == optionValue?.options_values_id
        );

        if (newTempOpt) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const renderSelectionUI = (optionValue) => {
    const newTempp = addonsObj?.attributes?.find(
      (rt) => rt?.options_values_id == optionValue?.options_values_id
    );

    if (newTempp && Object?.keys(newTempp)?.length) {
      const newArr = {};
      newTempp?.second_level_attributes?.map((ry) => {
        if (newArr?.hasOwnProperty(ry?.options_id)) {
          newArr[ry?.options_id].options_value_name.push(
            RESTAURANTS?.is_display_modifier_quantity === 1
              ? ry?.quantity + " x " + ry?.products_options_values_name
              : ry?.products_options_values_name
          );
        } else {
          newArr[ry?.options_id] = {};
          newArr[ry?.options_id] = {
            option_name: ry?.products_options_name,
            options_value_name: [
              RESTAURANTS?.is_display_modifier_quantity === 1
                ? ry?.quantity + " x " + ry?.products_options_values_name
                : ry?.products_options_values_name,
            ],
            options_value_text: ry?.options_value_text,
            second_level_attributes: {},
          };
          if (ry?.third_level_attributes?.length) {
            ry?.third_level_attributes?.map((ey) => {
              const secondLevel =
                newArr[ry?.options_id].second_level_attributes;
              if (secondLevel?.hasOwnProperty(ey?.options_id)) {
                secondLevel[ey?.options_id].options_value_name.push(
                  RESTAURANTS?.is_display_modifier_quantity === 1
                    ? ey?.quantity + " x " + ey?.products_options_values_name
                    : ey?.products_options_values_name
                );
              } else {
                secondLevel[ey?.options_id] = {
                  option_name: ey?.products_options_name,
                  options_value_name: [
                    RESTAURANTS?.is_display_modifier_quantity === 1
                      ? ey?.quantity + " x " + ey?.products_options_values_name
                      : ey?.products_options_values_name,
                  ],
                  options_value_text: ey?.options_value_text,
                  third_level_attributes: {},
                };
                if (ey?.fourth_level_attributes?.length) {
                  ey?.fourth_level_attributes?.map((it) => {
                    const thirdLevel =
                      newArr[ry?.options_id].second_level_attributes[
                        ey.options_id
                      ]?.third_level_attributes;
                    if (thirdLevel?.hasOwnProperty(it?.options_id)) {
                      thirdLevel[it?.options_id].options_value_name.push(
                        RESTAURANTS?.is_display_modifier_quantity === 1
                          ? it?.quantity +
                              " x " +
                              it?.products_options_values_name
                          : it?.products_options_values_name
                      );
                    } else {
                      thirdLevel[it?.options_id] = {
                        option_name: it?.products_options_name,
                        options_value_name: [
                          RESTAURANTS?.is_display_modifier_quantity === 1
                            ? it?.quantity +
                              " x " +
                              it?.products_options_values_name
                            : it?.products_options_values_name,
                        ],
                        options_value_text: it?.options_value_text,
                      };
                    }
                  });
                }
              }
            });
          }
        }
      });
      return Object.keys(newArr)?.map((tt, index) => {
        const textValue = newArr[tt]?.options_value_name;
        const textName = newArr[tt]?.option_name;
        const specialName = newArr[tt]?.options_value_text;

        return textName != "Special Instructions" ? (
          <>
            <div className="select-item border-bottom pb-2 mb-2" key={index}>
              <span className="fw-bold">{textName}</span>
              <small>{textValue?.join(", ")}</small>
            </div>
            {Object.keys(newArr[tt]?.second_level_attributes)?.map(
              (ey, index2) => {
                const textValue =
                  newArr[tt]?.second_level_attributes?.[ey]?.options_value_name;
                const textName =
                  newArr[tt]?.second_level_attributes?.[ey]?.option_name;
                const specialName =
                  newArr[tt]?.second_level_attributes?.[ey]?.options_value_text;
                return textName != "Special Instructions" ? (
                  <>
                    <div
                      className="select-item border-bottom pb-2 mb-2"
                      key={index2}
                    >
                      <span className="fw-bold">{textName}</span>
                      <small>{textValue?.join(", ")}</small>
                    </div>
                    {Object.keys(
                      newArr[tt]?.second_level_attributes[ey]
                        ?.third_level_attributes
                    )?.map((dy, index2) => {
                      const textValue =
                        newArr[tt]?.second_level_attributes?.[ey]
                          ?.third_level_attributes[dy]?.options_value_name;
                      const textName =
                        newArr[tt]?.second_level_attributes?.[ey]
                          ?.third_level_attributes[dy]?.option_name;
                      const specialName =
                        newArr[tt]?.second_level_attributes?.[ey]
                          ?.third_level_attributes[dy]?.options_value_text;
                      return textName != "Special Instructions" ? (
                        <>
                          <div
                            className="select-item border-bottom pb-2 mb-2"
                            key={index2}
                          >
                            <span className="fw-bold">{textName}</span>
                            <small>{textValue?.join(", ")}</small>
                          </div>
                        </>
                      ) : specialName ? (
                        <div
                          className="select-item border-bottom pb-2 mb-2"
                          key={index2}
                        >
                          <span className="fw-bold">{textName}</span>
                          <small>{specialName}</small>
                        </div>
                      ) : null;
                    })}
                  </>
                ) : specialName ? (
                  <div
                    className="select-item border-bottom pb-2 mb-2"
                    key={index2}
                  >
                    <span className="fw-bold">{textName}</span>
                    <small>{specialName}</small>
                  </div>
                ) : null;
              }
            )}
          </>
        ) : specialName ? (
          <div className="select-item border-bottom pb-2 mb-2" key={index}>
            <span className="fw-bold">{textName}</span>
            <small>{specialName}</small>
          </div>
        ) : null;
      });
    }
  };

  const handelAddUpdateCart = (isClearOlderCart) => {
    const allOptionIds = [];
    const allSelectedOptionIds = [];
    const selectedArray = [];
    //return;
    addonsObj?.attributes?.map((slo) => {
      selectedArray.push(slo);
    });

    ADDONS_DETAILS?.map((slo) => {
      if (slo?.attributes_min_select > 0) {
        allOptionIds.push(slo.options_id);
      }
    });
    addonsObj?.attributes?.map((slo) => {
      if (slo?.attributes_min_select > 0) {
        allSelectedOptionIds.push(slo.options_id);
      }
    });

    /* Code by ss */
    let anyError = false;
    let errorMessage = "";
    addonsObj?.attributes.forEach((attribute) => {
      const optionsValuesId = attribute.options_values_id;
      ADDONS_DETAILS.forEach((addon) => {
        const option = addon.optionValues.find(
          (option) => option.options_values_id === optionsValuesId
        );
        if (typeof option !== "undefined") {
          option?.second_level_options?.forEach((secondLevelOption) => {
            if (
              secondLevelOption.attributes_min_select > 0 &&
              attribute.second_level_attributes.length <
                secondLevelOption.attributes_min_select
            ) {
              errorMessage =
                "Please select inner item of " +
                option?.products_options_values_name;
              anyError = true;
              return;
            }
          });

          if (anyError) return;
          attribute?.second_level_attributes?.map((sec_attribute) => {
            option?.second_level_options?.forEach((secondLevelOption) => {
              const sec_option = secondLevelOption.optionValues.find(
                (option) =>
                  option.options_values_id === sec_attribute.options_values_id
              );
              if (typeof sec_option !== "undefined") {
                sec_option?.third_level_options?.forEach((thirdLevelOption) => {
                  if (
                    thirdLevelOption.attributes_min_select > 0 &&
                    sec_attribute?.third_level_attributes?.length <
                      thirdLevelOption.attributes_min_select
                  ) {
                    errorMessage =
                      "Please select inner item of " +
                      sec_option?.products_options_values_name;
                    anyError = true;
                    return;
                  }
                });
                if (anyError) return;
                sec_attribute?.third_level_attributes?.map(
                  (third_attribute) => {
                    sec_option?.third_level_options?.forEach(
                      (thirdLevelOption) => {
                        const third_option = thirdLevelOption.optionValues.find(
                          (option) =>
                            option.options_values_id ===
                            third_attribute.options_values_id
                        );
                        if (typeof third_option !== "undefined") {
                          third_option?.fourth_level_options?.forEach(
                            (fourthLevelOption) => {
                              if (
                                fourthLevelOption.attributes_min_select > 0 &&
                                third_attribute?.fourth_level_attributes
                                  ?.length <
                                  fourthLevelOption.attributes_min_select
                              ) {
                                errorMessage =
                                  "Please select inner item of " +
                                  third_option?.products_options_values_name;
                                anyError = true;
                                return;
                              }
                            }
                          );
                          if (anyError) return;
                        }
                      }
                    );
                  }
                );
              }
            });
          });
        }
      });
    });
    if (anyError) {
      errorToast(errorMessage);
      return;
    }
    // ********************

    for (let addon of ADDONS_DETAILS) {
      if (
        addon.products_options_type === 2 ||
        addon.products_options_type === 3
      ) {
        let optionsId = addon.options_id;
        if (addon.attributes_min_select > 0) {
          let selectedAttributesQuantity = addonsObj.attributes
            .filter((att) => att.options_id === optionsId)
            ?.reduce((prev, curr) => prev + curr.quantity, 0);
          if (addon.attributes_min_select > selectedAttributesQuantity) {
            const errorMessage = `Minimum  ${addon.attributes_min_select}
              quantity is required for ${addon.products_options_name}`;
            errorToast(errorMessage);

            const errorPayload = {
              addon_details: ADDONS_DETAILS,
              selectedAddon: addonsObj,
              errorMessage,
            };
            dispatch(
              GET_SAVE_CART_LOG(
                errorPayload,
                (res, otherRes, loading, error) => {}
              )
            );

            anyError = true;
            break;
          }

          if (
            addon.attributes_max_select !== 0 &&
            addon.attributes_max_select < selectedAttributesQuantity
          ) {
            const errorMessage = `Maximum  ${addon.attributes_max_select}
              quantity can be choose for ${addon.products_options_name}`;
            errorToast(errorMessage);

            const errorPayload = {
              addon_details: ADDONS_DETAILS,
              selectedAddon: addonsObj,
              errorMessage,
            };
            dispatch(
              GET_SAVE_CART_LOG(
                errorPayload,
                (res, otherRes, loading, error) => {}
              )
            );
            anyError = true;
            break;
          }
        } else {
          const selectedAttributes = addonsObj.attributes.find(
            (att) => att.options_id === optionsId
          );
          const selectedParticularAttributes =
            selectedAttributes?.optionValues1?.find(
              (it) =>
                it?.options_values_id === selectedAttributes?.options_values_id
            );

          const attributeDetails =
            selectedParticularAttributes?.second_level_options?.find(
              (it) => it?.attributes_min_select
            );

          if (
            attributeDetails?.attributes_min_select >
            selectedAttributes?.second_level_attributes?.filter(
              (it) => it.options_id === attributeDetails?.options_id
            ).length
          ) {
            const errorMessage = `Please select inner item of ${selectedParticularAttributes?.products_options_values_name}`;
            errorToast(errorMessage);

            const errorPayload = {
              addon_details: ADDONS_DETAILS,
              selectedAddon: addonsObj,
              errorMessage,
            };
            dispatch(
              GET_SAVE_CART_LOG(
                errorPayload,
                (res, otherRes, loading, error) => {}
              )
            );

            anyError = true;
            break;
          }
        }

        /* Inner Item validation */
        // for (let innerAddon of addon.optionValues) {
        //   if (innerAddon.second_level_options.length) {
        //     let selectedAttributes = addonsObj.attributes.filter(
        //       (att) => att.options_values_id === innerAddon.options_values_id
        //     );
        //     let totalInnerQty = 0;
        //     if (selectedAttributes.length) {
        //       selectedAttributes.forEach((attributes) => {
        //         attributes.second_level_attributes.forEach(
        //           (second_attribue) => {
        //             totalInnerQty += second_attribue.quantity;
        //           }
        //         );
        //       });
        //       if (
        //         innerAddon.second_level_options[0].min_aggregate_quantity >
        //         totalInnerQty /* &&
        //         innerAddon.second_level_options[0].attributes_min_select > 0 */
        //       ) {
        //         errorToast(
        //           `Minimum ${innerAddon.second_level_options[0].min_aggregate_quantity}
        //       quantity is required for ${innerAddon.second_level_options[0].products_options_name}`
        //         );
        //         anyError = true;
        //         break;
        //       }

        //       if (
        //         innerAddon.second_level_options[0].max_aggregate_quantity !==
        //           0 &&
        //         innerAddon.second_level_options[0].max_aggregate_quantity <
        //           totalInnerQty
        //       ) {
        //         errorToast(
        //           `Maximum  ${innerAddon.second_level_options[0].max_aggregate_quantity}
        //       quantity can be choose for ${innerAddon.second_level_options[0].products_options_name}`
        //         );
        //         anyError = true;
        //         return;
        //       }
        //     }
        //   }
        // }

        // if (anyError) {
        //   return;
        // }

        let totalQtySelected = 0;
        addonsObj?.attributes.forEach((attribute) => {
          if (attribute.options_id === optionsId) {
            totalQtySelected += attribute.quantity;
          }
        });

        if (
          addon.support_choice_quantities &&
          totalQtySelected < addon.min_aggregate_quantity /* &&
          addon.attributes_min_select > 0 */
        ) {
          const errorMessage = `Minimum ${addon.min_aggregate_quantity}  quantity is required for ${addon.products_options_name}`;
          errorToast(errorMessage);
          const errorPayload = {
            addon_details: ADDONS_DETAILS,
            selectedAddon: addonsObj,
            errorMessage,
          };
          dispatch(
            GET_SAVE_CART_LOG(
              errorPayload,
              (res, otherRes, loading, error) => {}
            )
          );
          anyError = true;
          return;
        }
        //return;

        if (
          addon.support_choice_quantities &&
          addon.max_aggregate_quantity !== 0 &&
          totalQtySelected > addon.max_aggregate_quantity
        ) {
          const errorMessage = `Maximum ${addon.max_aggregate_quantity} quantity can be choose for ${addon.products_options_name}`;
          errorToast(errorMessage);
          const errorPayload = {
            addon_details: ADDONS_DETAILS,
            selectedAddon: addonsObj,
            errorMessage,
          };
          dispatch(
            GET_SAVE_CART_LOG(
              errorPayload,
              (res, otherRes, loading, error) => {}
            )
          );
          anyError = true;
          return;
        }
      }
    }

    if (anyError) {
      return;
    }

    /* Basket Validation */
    //**********************Mukesh is handling this on his end he told me to comment it out */
    // if (
    //   addonsObj.customers_basket_quantity <
    //   ADDONS_DETAILS_META.products_quantity_order_min
    // ) {
    //   errorToast(
    //     `Minimum ${ADDONS_DETAILS_META.products_quantity_order_min} basket is required`
    //   );
    //   return;
    // }
    // if (
    //   ADDONS_DETAILS_META.products_quantity_order_max !== 0 &&
    //   addonsObj.customers_basket_quantity >
    //     ADDONS_DETAILS_META.products_quantity_order_max
    // ) {
    //   errorToast(
    //     `Maximum ${ADDONS_DETAILS_META.products_quantity_order_min} basket can be ordered`
    //   );
    //   return;
    // }
    //**********************Mukesh is handling this on his end he told me to comment it out */

    /* End of code */
    const resultIdsArr = [...allOptionIds, ...allSelectedOptionIds];
    let removeIds = removeDuplicates(resultIdsArr);
    removeIds = removeIds?.filter((ee) => {
      // const newTemp = selectedArray?.find((et) => et?.options_id == ee);
      // if (
      //   !(
      //     newTemp?.attributes_min_select <=
      //     allSelectedOptionIds?.filter((rt) => rt == ee).length
      //   )
      // ) {
      //   return ee;
      // }
      const newTemp = selectedArray?.find((et) => et?.options_id == ee);
      let selectedAttributesQuantity = addonsObj.attributes
        .filter((att) => att.options_id === ee)
        ?.reduce((prev, curr) => prev + curr.quantity, 0);
      if (!(newTemp?.attributes_min_select <= selectedAttributesQuantity)) {
        return ee;
      }
    });
    setFirstLevelErrorHandlerArr(removeIds);
    setTemp(!temp);
    if (removeIds?.length == 0) {
      const attributeResult = addonsObj?.attributes?.map((dt) => {
        if (dt.options_value_text) {
          return responseReturnFunction(dt);
        } else {
          if (dt?.second_level_attributes?.length !== 0) {
            return {
              ...responseReturnFunction(dt, false),
              second_level_attributes: dt?.second_level_attributes?.map(
                (sl) => {
                  if (sl?.options_value_text) {
                    return responseReturnFunction(sl);
                  } else {
                    if (sl?.third_level_attributes?.length !== 0) {
                      return {
                        ...responseReturnFunction(sl, false),
                        third_level_attributes: sl?.third_level_attributes?.map(
                          (el) => {
                            if (el?.options_value_text) {
                              return responseReturnFunction(el);
                            } else {
                              if (el?.fourth_level_attributes?.length !== 0) {
                                return {
                                  ...responseReturnFunction(el, false),
                                  fourth_level_attributes:
                                    el?.fourth_level_attributes?.map((it) => {
                                      if (it?.options_value_text) {
                                        return responseReturnFunction(it);
                                      } else {
                                        if (it?.options_value_text != "") {
                                          return responseReturnFunction(
                                            it,
                                            false
                                          );
                                        } else {
                                          return responseReturnFunction(it);
                                        }
                                      }
                                    }),
                                };
                              } else {
                                if (el.options_value_text !== "") {
                                  return responseReturnFunction(el, false);
                                } else {
                                  return responseReturnFunction(el);
                                }
                              }
                            }
                          }
                        ),
                      };
                    } else {
                      if (sl.options_value_text !== "") {
                        return responseReturnFunction(sl, false);
                      } else {
                        return responseReturnFunction(sl);
                      }
                    }
                  }
                }
              ),
            };
          } else {
            if (dt.options_value_text !== "") {
              return responseReturnFunction(dt, false);
            } else {
              return responseReturnFunction(dt);
            }
          }
        }
      });

      // const attributeResult = addonsObj?.attributes?.map((dt) => {
      //   if (dt.options_value_text) {
      //     if (dt?.second_level_attributes.length !== 0) {
      //       return {
      //         options_id: dt?.options_id,
      //         options_values_id: dt?.options_values_id,
      //         options_value_text: dt?.options_value_text ?? "",
      //         quantity: dt?.quantity,
      //         free_modifier_quantity: dt?.free_modifier_quantity,
      //         second_level_attributes: dt?.second_level_attributes?.map(
      //           (sl) => {
      //             if (sl?.options_value_text) {
      //               return {
      //                 options_id: sl?.options_id,
      //                 options_values_id: sl.options_values_id,
      //                 options_value_text: sl?.options_value_text ?? "",
      //                 quantity: sl?.quantity,
      //                 free_modifier_quantity: sl?.free_modifier_quantity,
      //               };
      //             } else {
      //               if (sl?.options_value_text != "") {
      //                 return {
      //                   options_id: sl?.options_id,
      //                   options_values_id: sl.options_values_id,
      //                   quantity: sl?.quantity,
      //                   free_modifier_quantity: sl?.free_modifier_quantity,
      //                 };
      //               } else {
      //                 return {
      //                   options_id: sl?.options_id,
      //                   options_values_id: sl.options_values_id,
      //                   options_value_text: "",
      //                   quantity: sl?.quantity,
      //                   free_modifier_quantity: sl?.free_modifier_quantity,
      //                 };
      //               }
      //             }
      //           }
      //         ),
      //       };
      //     } else {

      //     }
      //   } else {
      //     if (dt?.second_level_attributes?.length !== 0) {
      //       return {
      //         options_id: dt?.options_id,
      //         options_values_id: dt?.options_values_id,
      //         quantity: dt?.quantity,
      //         free_modifier_quantity: dt?.free_modifier_quantity,
      //         second_level_attributes: dt?.second_level_attributes?.map(
      //           (sl) => {
      //             if (sl?.options_value_text) {
      //               return {
      //                 options_id: sl?.options_id,
      //                 options_values_id: sl.options_values_id,
      //                 options_value_text: sl?.options_value_text ?? "",
      //                 quantity: sl?.quantity,
      //                 free_modifier_quantity: sl?.free_modifier_quantity,
      //               };
      //             } else {
      //               if (sl?.options_value_text != "") {
      //                 return {
      //                   options_id: sl?.options_id,
      //                   options_values_id: sl.options_values_id,
      //                   quantity: sl?.quantity,
      //                   free_modifier_quantity: sl?.free_modifier_quantity,
      //                 };
      //               } else {
      //                 return {
      //                   options_id: sl?.options_id,
      //                   options_values_id: sl.options_values_id,
      //                   options_value_text: "",
      //                   quantity: sl?.quantity,
      //                   free_modifier_quantity: sl?.free_modifier_quantity,
      //                 };
      //               }
      //             }
      //           }
      //         ),
      //       };
      //     } else {
      //       if (dt.options_value_text !== "") {
      //         return {
      //           options_id: dt?.options_id,
      //           options_values_id: dt?.options_values_id,
      //           quantity: dt?.quantity,
      //           free_modifier_quantity: dt?.free_modifier_quantity,
      //         };
      //       } else {
      //         return {
      //           options_id: dt?.options_id,
      //           options_values_id: dt?.options_values_id,
      //           options_value_text: "",
      //           quantity: dt?.quantity,
      //           free_modifier_quantity: dt?.free_modifier_quantity,
      //         };
      //       }
      //     }
      //   }
      // });

      const resultPayload =
        attributeResult.length !== 0
          ? {
              attributes: attributeResult,
              customers_basket_quantity: addonsObj?.customers_basket_quantity,
              group_id: addonsObj?.group_id,
              products_id: addonsObj?.products_id,
              restaurant_id: addonsObj?.restaurant_id,
              // is_clear_old_cart: addonsObj?.is_clear_old_cart,
              is_clear_old_cart: isClearOlderCart ?? "",
              customers_basket_id: addonsObj?.customers_basket_id,
              ...(addonsObj?.product_to_category_id && {
                product_to_category_id: addonsObj?.product_to_category_id,
              }),
            }
          : {
              customers_basket_quantity: addonsObj?.customers_basket_quantity,
              group_id: addonsObj?.group_id,
              products_id: addonsObj?.products_id,
              restaurant_id: addonsObj?.restaurant_id,
              // is_clear_old_cart: addonsObj?.is_clear_old_cart,
              is_clear_old_cart: isClearOlderCart ?? "",
              customers_basket_id: addonsObj?.customers_basket_id,
              ...(addonsObj?.product_to_category_id && {
                product_to_category_id: addonsObj?.product_to_category_id,
              }),
            };

      dispatch(
        GET_ADD_UPDATE_ATTRIBUTE(
          resultPayload,
          (res, otherRes, loading, error) => {
            setAddUpdateAttributeLoader(loading);
            dispatch(SHOW_MENU_BOTTOM_NAVIGATION_VISIBLE(false));

            if (otherRes?.error?.is_other_restaurant_item_added == 1) {
              setClearCartMessage(otherRes?.message);
              toggleCartClearModal(otherRes?.error);
            }
            if (!error) {
              // if (!GROUP_ID) {
              dispatch(SET_GROUP_ID(res?.data?.group_id));
              // }
              const newTemp = CART_ATTRIBUTE.find(
                (dt) => dt.products_id == addonsObj.products_id
              );
              const newTempIndex = CART_ATTRIBUTE.findIndex(
                (dt) => dt.products_id == addonsObj.products_id
              );
              if (newTemp) {
                CART_ATTRIBUTE[newTempIndex] = addonsObj;
                dispatch(SET_CART_ADDONS(CART_ATTRIBUTE));
              } else {
                // CART_ADDONS.push(addonsObj);
                const cartLength = CART_ATTRIBUTE?.length;
                CART_ATTRIBUTE[cartLength] = addonsObj;
                dispatch(SET_CART_ADDONS(CART_ATTRIBUTE));
              }
              if (location.pathname == "/checkout") {
                dispatch(
                  GET_CALL_CART_API_IN_ORDER_CHECKOUT(
                    !RECALL_CART_API_ON_ORDER_CHECKOUT_STATE
                  )
                );
              } else {
                if (ASAP_ADVANCE_STATE == 1) {
                  const params = {
                    group_id: GROUP_ID ? GROUP_ID : res.data.group_id,
                    lat_from: DEFAULT_ADDRESS?.address_lat ?? 0,
                    lng_from: DEFAULT_ADDRESS?.address_lng ?? 0,
                    postcode: DEFAULT_ADDRESS?.entry_postcode ?? "",
                    asap_or_advance: ASAP_ADVANCE_STATE,
                    service_type: SERVICE_TYPE,
                    city: DEFAULT_ADDRESS?.entry_city,
                  };
                  dispatch(
                    GET_CART_LIST({ params }, (res, loading, error) => {
                      if (res?.restaurant) {
                        dispatch(
                          PREVENT_RESTAURANT_NAME(res?.restaurant?.name)
                        );
                        // dispatch(PREVENT_RESTAURANT_ID(res?.restaurant?.id));
                        dispatch(PREVENT_RESTAURANT_ID(res?.restaurant));
                        // dispatch(CART_API_TRIGGER_LOADER(loading));
                        // if (!error) {
                        // }
                      }
                    })
                  );
                } else {
                  const currentDate =
                    moment(ASAP_DATE_STATE).format("YYYY-MM-DD");
                  const time = ASAP_TIME_STATE?.time?.split("-");
                  const Time = time[1]?.replace(/\s/g, "");
                  const currentTime = moment(Time, "h:mm A").format("HH:mm");
                  const params = {
                    group_id: GROUP_ID ? GROUP_ID : res.data.group_id,
                    lat_from: DEFAULT_ADDRESS?.address_lat ?? 0,
                    lng_from: DEFAULT_ADDRESS?.address_lng ?? 0,
                    postcode: DEFAULT_ADDRESS?.entry_postcode ?? "",
                    asap_or_advance: ASAP_ADVANCE_STATE,
                    date: currentDate,
                    time: currentTime,
                    service_type: SERVICE_TYPE,
                    city: DEFAULT_ADDRESS?.entry_city,
                  };
                  dispatch(
                    GET_CART_LIST({ params }, (res, loading, error) => {
                      if (res?.restaurant) {
                        dispatch(
                          PREVENT_RESTAURANT_NAME(res?.restaurant?.name)
                        );
                        dispatch(PREVENT_RESTAURANT_ID(res?.restaurant));
                      }
                    })
                  );
                }
              }
              setAddonsObj({});
              onHide();
            } else {
              console.log("error", error);
              if (otherRes?.error?.is_other_restaurant_item_added !== 1) {
                if (error?.message) {
                  errorToast(error?.message);
                }
              }
              return;
            }
          }
        )
      );
    } else {
      errorToast("Please select item");
    }
  };

  function removeDuplicates(arr) {
    return [...new Set(arr)];
  }

  const handelAddBasketQuantity = (title) => {
    if (title == "add") {
      setAddonsObj((prevState) => ({
        ...prevState,
        customers_basket_quantity:
          parseFloat(prevState.customers_basket_quantity) +
          ADDONS_DETAILS_META.quantity_increment,
      }));
    } else {
      setAddonsObj((prevState) => ({
        ...prevState,
        customers_basket_quantity:
          parseFloat(prevState.customers_basket_quantity) -
          ADDONS_DETAILS_META.quantity_increment,
      }));
    }
    setTemp(!temp);
  };

  // const renderAddToCartPriceCount = () => {
  //   const optionsIds = [];
  //   const innerOptionsIds = [];

  //   let totalPrice = 0;
  //   let outerOptionPrice = 0;
  //   let innerOptionPrice = 0;

  //   if (addonsObj?.attributes?.length > 0) {
  //     for (let attribute of addonsObj?.attributes) {
  //       outerOptionPrice = 0;
  //       innerOptionPrice = 0;

  //       if (attribute?.second_level_attributes?.length > 0) {
  //         for (let attributeNested of attribute?.second_level_attributes) {
  //           if (attributeNested?.free_modifier_quantity) {
  //             const index = innerOptionsIds.findIndex(
  //               (o) =>
  //                 o.optionId_Price ===
  //                 `${attributeNested.options_id}#${attributeNested?.options_values_price}`
  //             );
  //             if (index !== -1) {
  //               innerOptionsIds[index].quantity += attributeNested?.quantity;
  //             } else {
  //               innerOptionsIds.push({
  //                 options_id: attributeNested.options_id,
  //                 quantity: attributeNested?.quantity,
  //                 optionId_Price: `${attributeNested.options_id}#${attributeNested?.options_values_price}`,
  //               });
  //             }

  //             const isMin =
  //               Math.min(
  //                 ...attributeNested?.optionValues1.map((item) =>
  //                   parseFloat(item.options_values_price)
  //                 )
  //               ) === parseFloat(attributeNested.options_values_price);
  //             if (isMin) {
  //               let innerOptionIndex = innerOptionsIds.findIndex(
  //                 (o) =>
  //                   o.optionId_Price ===
  //                   `${attributeNested.options_id}#${attributeNested?.options_values_price}`
  //               );
  //               let netQty =
  //                 innerOptionsIds[innerOptionIndex]?.quantity -
  //                 attributeNested?.free_modifier_quantity;
  //               netQty = netQty > 0 ? netQty : 0;
  //               innerOptionsIds[innerOptionIndex].quantity -= netQty;
  //               innerOptionPrice =
  //                 parseFloat(attributeNested?.options_values_price) * netQty;
  //             } else {
  //               totalPrice +=
  //                 parseFloat(attributeNested?.options_values_price) *
  //                 attributeNested?.quantity;
  //             }
  //           } else {
  //             totalPrice +=
  //               parseFloat(attributeNested?.options_values_price) *
  //               attributeNested?.quantity;
  //           }
  //         }
  //       }
  //       totalPrice += innerOptionPrice;
  //       if (attribute?.free_modifier_quantity) {
  //         //console.log("attribute", attribute);
  //         const index = optionsIds.findIndex(
  //           (o) =>
  //             o.optionId_Price ===
  //             `${attribute.options_id}#${attribute?.options_values_price}`
  //         );
  //         if (index !== -1) {
  //           optionsIds[index].quantity += attribute?.quantity;
  //         } else {
  //           optionsIds.push({
  //             options_id: attribute.options_id,
  //             quantity: attribute?.quantity,
  //             optionId_Price: `${attribute.options_id}#${attribute?.options_values_price}`,
  //           });
  //         }
  //         const isMin =
  //           Math.min(
  //             ...attribute?.optionValues1?.map((item) =>
  //               parseFloat(item.options_values_price)
  //             )
  //           ) === parseFloat(attribute.options_values_price);
  //         if (isMin) {
  //           let optionIndex = optionsIds.findIndex(
  //             (o) =>
  //               o.optionId_Price ===
  //               `${attribute.options_id}#${attribute?.options_values_price}`
  //           );
  //           let netQty =
  //             optionsIds[optionIndex]?.quantity -
  //             attribute?.free_modifier_quantity;
  //           netQty = netQty > 0 ? netQty : 0;
  //           optionsIds[optionIndex].quantity -= netQty;
  //           outerOptionPrice =
  //             parseFloat(attribute?.options_values_price) * netQty;
  //         } else {
  //           totalPrice +=
  //             parseFloat(attribute?.options_values_price) * attribute?.quantity;
  //         }
  //       } else {
  //         totalPrice +=
  //           parseFloat(attribute?.options_values_price) * attribute?.quantity;
  //       }
  //       totalPrice += outerOptionPrice;
  //     }
  //   }

  //   const result =
  //     (addonsObj?.productPrice + totalPrice) *
  //     addonsObj?.customers_basket_quantity;
  //   return result && !isNaN(result) && result?.toFixed(2);
  //   //return totalPrice?.toFixed(2);
  // };

  const renderAddToCartPriceCount = () => {
    const firstOptionsIds = [];
    const secondOptionsIds = [];
    const thirdOptionsIds = [];
    const fourthOptionsIds = [];

    let totalPrice = 0;
    let firstOptionPrice = 0;
    let secondOptionPrice = 0;
    let thirdOptionPrice = 0;
    let fourthOptionPrice = 0;

    if (addonsObj?.attributes?.length > 0) {
      for (let attribute of addonsObj?.attributes) {
        firstOptionPrice = 0;
        secondOptionPrice = 0;
        thirdOptionPrice = 0;
        fourthOptionPrice = 0;

        if (attribute?.second_level_attributes?.length > 0) {
          for (let attributeNested of attribute?.second_level_attributes) {
            if (attributeNested?.third_level_attributes?.length > 0) {
              for (let attributeSecondNested of attributeNested?.third_level_attributes) {
                if (
                  attributeSecondNested?.fourth_level_attributes?.length > 0
                ) {
                  for (let attributeThirdNested of attributeSecondNested?.fourth_level_attributes) {
                    const { price, outerPrice } = checkAndReturnTotalPrice(
                      attributeThirdNested,
                      fourthOptionsIds,
                      fourthOptionPrice
                    );
                    totalPrice += price;
                    fourthOptionPrice = outerPrice;
                  }
                }
                totalPrice += fourthOptionPrice;
                const { price, outerPrice } = checkAndReturnTotalPrice(
                  attributeSecondNested,
                  thirdOptionsIds,
                  thirdOptionPrice
                );
                totalPrice += price;
                thirdOptionPrice = outerPrice;
              }
            }
            totalPrice += thirdOptionPrice;
            const { price, outerPrice } = checkAndReturnTotalPrice(
              attributeNested,
              secondOptionsIds,
              secondOptionPrice
            );
            totalPrice += price;
            secondOptionPrice = outerPrice;
          }
        }
        totalPrice += secondOptionPrice;
        const { price, outerPrice } = checkAndReturnTotalPrice(
          attribute,
          firstOptionsIds,
          firstOptionPrice
        );
        totalPrice += price;
        firstOptionPrice = outerPrice;
        totalPrice += firstOptionPrice;
      }
    }

    const result =
      (addonsObj?.productPrice + totalPrice) *
      addonsObj?.customers_basket_quantity;
    return result && !isNaN(result) && result?.toFixed(2);
  };

  const toggleCartClearModal = (res) => {
    setIsAnotherResOrderAddedKey(res?.is_other_restaurant_item_added ?? "");
    setShowCartClearModal(!showCartClearModal);
  };

  const handelClearCart = () => {
    onHide();
    handelAddUpdateCart(isAnotherResOrderAddedKey);
  };

  const [showProductDesc, setShowProductDesc] = useState(true);

  const scrollModal = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
    setShowProductDesc(scrollPercentage > 3 ? false : true);
  };

  // ALL FUNCTIONS FOR 3rd LEVEL
  const showThirdLevelBlockUi = (data, optVal) => {
    const firstIndex = addonsObj?.attributes?.findIndex(
      (ty) => ty?.options_values_id == activeSecondLevelBlock?.options_values_id
    );
    const newSecondIndex = addonsObj?.attributes?.[
      firstIndex
    ]?.second_level_attributes?.findIndex(
      (item) =>
        item.options_values_id === activeThirdLevelBlock?.options_values_id
    );
    const newTemp =
      addonsObj?.attributes[firstIndex]?.second_level_attributes[newSecondIndex]
        ?.third_level_attributes;
    const defaultChecked = ADDONS_DETAILS?.find(
      (ty) => ty?.options_id == activeSecondLevelBlock?.options_id
    )
      ?.optionValues?.find(
        (item) =>
          item.options_values_id === activeSecondLevelBlock?.options_values_id
      )
      ?.second_level_options?.find(
        (item) => item?.options_id == data?.options_id
      )
      ?.optionValues?.find(
        (item) => item.options_values_id === optVal?.options_values_id
      );
    thirdLevelUseEffect(defaultChecked?.third_level_options, newTemp);
    setActiveThirdLevelBlock({ ...optVal, options_id: data?.options_id });
    setVisibleSecondLevelBlock(false);
    setActiveNestedMode("thirdLevel");
    setVisibleThirdLevelBlock(!visibleThirdLevelBlock);
  };

  const handelShowThirdLevelModiefire = (data, optVal, type, status) => {
    setActiveThirdLevelBlock(optVal);
    if (type == "check") {
      if (status == true) {
        setShowThirdLevelBlock(true);
      } else {
        setShowThirdLevelBlock(false);
      }
    }
    if (type == "radioType") {
      if (status == true) {
        setShowThirdLevelBlock(true);
      } else {
        setShowThirdLevelBlock(false);
      }
    }
    if (type == "testType") {
      if (status == true) {
        setShowThirdLevelBlock(true);
      } else {
        setShowThirdLevelBlock(false);
      }
    }
  };

  const handelShowThirdLevelModifureOnEvent = (data, optVal, type, status) => {
    setActiveThirdLevelBlock(optVal);
    if (type == "check") {
      if (status == true) {
        setShowThirdLevelBlock(true);
      } else {
        setShowThirdLevelBlock(false);
      }
    }
    if (type == "radioType") {
      if (status == true) {
        setShowThirdLevelBlock(true);
      } else {
        setShowThirdLevelBlock(false);
      }
    }
    if (type == "testType") {
      if (status == true) {
        setShowThirdLevelBlock(true);
      } else {
        setShowThirdLevelBlock(false);
      }
    }
  };
  // ALL FUNCTIONS FOR 3rd LEVEL

  // ALL FUNCTIONS FOR 4rd LEVEL
  const showFourthLevelBlockUi = (data, optVal) => {
    const firstIndex = addonsObj?.attributes?.findIndex(
      (ty) => ty?.options_values_id == activeSecondLevelBlock?.options_values_id
    );
    const newSecondIndex = addonsObj?.attributes?.[
      firstIndex
    ]?.second_level_attributes?.findIndex(
      (item) =>
        item.options_values_id === activeThirdLevelBlock?.options_values_id
    );
    const newThirdIndex = addonsObj?.attributes?.[
      firstIndex
    ]?.second_level_attributes?.[
      newSecondIndex
    ]?.third_level_attributes?.findIndex(
      (item) =>
        item.options_values_id === activeFourthLevelBlock?.options_values_id
    );
    const newTemp =
      addonsObj?.attributes[firstIndex]?.second_level_attributes[newSecondIndex]
        ?.third_level_attributes[newThirdIndex]?.fourth_level_attributes;

    const defaultChecked = ADDONS_DETAILS?.find(
      (ty) => ty?.options_id == activeSecondLevelBlock?.options_id
    )
      ?.optionValues?.find(
        (item) =>
          item.options_values_id === activeSecondLevelBlock?.options_values_id
      )
      ?.second_level_options?.find(
        (item) => item?.options_id == activeThirdLevelBlock?.options_id
      )
      ?.optionValues?.find(
        (item) =>
          item.options_values_id === activeThirdLevelBlock?.options_values_id
      )
      ?.third_level_options?.find(
        (item) => item?.options_id == data?.options_id
      )
      ?.optionValues?.find(
        (item) => item.options_values_id === optVal?.options_values_id
      );
    fourthLevelUseEffect(defaultChecked?.fourth_level_options, newTemp);
    setActiveFourthLevelBlock({ ...optVal, options_id: data?.options_id });
    // setVisibleSecondLevelBlock(false);
    setVisibleThirdLevelBlock(false);
    setActiveNestedMode("fourthLevel");
    setVisibleFourthLevelBlock(!visibleFourthLevelBlock);
  };

  const handelShowFourthLevelModiefire = (data, optVal, type, status) => {
    setActiveFourthLevelBlock(optVal);
    if (type == "check") {
      if (status == true) {
        setShowFourthLevelBlock(true);
      } else {
        setShowFourthLevelBlock(false);
      }
    }
    if (type == "radioType") {
      if (status == true) {
        setShowFourthLevelBlock(true);
      } else {
        setShowFourthLevelBlock(false);
      }
    }
    if (type == "testType") {
      if (status == true) {
        setShowFourthLevelBlock(true);
      } else {
        setShowFourthLevelBlock(false);
      }
    }
  };

  // ALL FUNCTIONS FOR 4rd LEVEL

  console.log("addons object", addonsObj);
  console.log(
    "3rd level data",
    activeThirdLevelBlock,
    "ADDONS_DETAILS",
    ADDONS_DETAILS
  );
  console.log("activeProduct test", activeProduct);

  return (
    <div>
      <div className="">
        {showCartClearModal ? (
          <ClearCartModal
            show={showCartClearModal}
            onHide={() => toggleCartClearModal()}
            message={clearCartMessage}
            loader={addUpdateAttributeLoader}
            handelClearCart={handelClearCart}
          />
        ) : (
          <Modal
            show={show}
            // onHide={() => false}
            onHide={() => {
              onHide();
              setShowSecondLevelBlock(false);
              setActiveSecondLevelBlock("");
              setAddonsObj({});
              setTriggerSetAttributeEffect(false);
            }}
            // className={`rest-list-modal ${
            //   !visibleSecondLevelBlock
            //     ? "first-level-modal"
            //     : "second-level-modal"
            // }`}
            className={`rest-list-modal ${
              activeNestedMode === "firstLevel"
                ? "first-level-modal"
                : "second-level-modal"
            }`}
            centered
          >
            <div
              // className={`product-modal-wrapper ${
              //   visibleSecondLevelBlock ? "second-level-block" : ""
              // }`}
              className={`product-modal-wrapper ${
                activeNestedMode !== "firstLevel" ? "second-level-block" : ""
              }`}
              onScroll={scrollModal}
            >
              {activeProduct?.products_image ||
              activeProduct?.product_to_category?.product?.products_image ? (
                <>
                  {/* {!visibleSecondLevelBlock && ( */}
                  {activeNestedMode === "firstLevel" && (
                    <div
                      className={`addons-modal-banner ${
                        !activeProduct?.products_description ? "no-desc" : ""
                      }`}
                    >
                      <img
                        src={
                          activeProduct?.products_image
                            ? activeProduct?.products_image
                            : activeProduct?.product_to_category?.product
                                ?.products_image
                            ? activeProduct?.product_to_category?.product
                                ?.products_image
                            : null
                        }
                        alt=""
                      />
                    </div>
                  )}
                </>
              ) : null}
              <Modal.Header className="border-0 pb-4 product-modal-heading">
                <div
                  // className={`d-flex justify-content-between align-item-center w-100 ${
                  //   visibleSecondLevelBlock &&
                  //   activeProduct?.products_description &&
                  //   "d-none"
                  // }`}
                  className={`d-flex justify-content-between align-item-center w-100 ${
                    activeNestedMode !== "firstLevel" && "d-none"
                  }`}
                >
                  <Modal.Title>
                    {activeProduct?.products_name
                      ? activeProduct?.products_name
                      : activeProduct?.product_name
                      ? activeProduct?.product_name
                      : ""}{" "}
                  </Modal.Title>
                </div>
                {/* {!visibleSecondLevelBlock && */}
                {activeNestedMode === "firstLevel" &&
                  activeProduct?.products_description && (
                    <span
                      className={`d-flex justify-content-center align-items-center ${
                        showProductDesc ? "show-desc" : "hide-desc"
                      }`}
                    >
                      <p style={{ fontSize: "14px" }} className="mb-0">
                        {activeProduct?.products_description}
                      </p>
                    </span>
                  )}
              </Modal.Header>
              <>
                {activeProduct?.products_image ||
                // activeProduct?.product?.products_image
                activeProduct?.product_to_category?.product?.products_image ? (
                  <>
                    {/* {!visibleSecondLevelBlock && ( */}
                    {activeNestedMode === "firstLevel" && (
                      <IconButton
                        className="cross-btn cbtn"
                        style={{ zIndex: "1111" }}
                        onClick={() => {
                          onHide();
                          setShowSecondLevelBlock(false);
                          setActiveSecondLevelBlock("");
                          setAddonsObj({});
                          setTriggerSetAttributeEffect(false);
                        }}
                      >
                        <CloseIcon style={{ cursor: "pointer" }} />
                      </IconButton>
                    )}
                  </>
                ) : (
                  <CloseIcon
                    className="cross-btn"
                    style={{ zIndex: "1111", cursor: "pointer" }}
                    onClick={() => {
                      onHide();
                      setShowSecondLevelBlock(false);
                      setActiveSecondLevelBlock("");
                      setAddonsObj({});
                      setTriggerSetAttributeEffect(false);
                    }}
                  />
                )}
              </>
              {activeNestedMode !== "firstLevel" && (
                <CloseIcon
                  onClick={() => {
                    onHide();
                    setShowSecondLevelBlock(false);
                    setActiveSecondLevelBlock("");
                    setAddonsObj({});
                    setTriggerSetAttributeEffect(false);
                  }}
                  style={{ cursor: "pointer" }}
                />
              )}

              {loader ? (
                <Container className=" d-flex align-items-center default-back">
                  <LottiLoader loader={true} />
                </Container>
              ) : (
                <Modal.Body>
                  <div
                    className={
                      activeProduct?.products_image ||
                      activeProduct?.product?.products_image
                        ? "add-cart-inner-modal build-own overflow_scroll_none"
                        : `add-cart-inner-modal build-own overflow_scroll_none with-addon-image ${
                            activeProduct?.products_description
                              ? "with-desc"
                              : ""
                          }`
                    }
                  >
                    {ADDONS_DETAILS?.length === 0 ? null : ( // </Container> //   </span> //     /> //       message="No attributes available." //       width={250} //       height={250} //     <LottiLoader //   <span> // <Container className=" d-flex justify-content-center align-items-center default-back"> //NO Attribute
                      <Form>
                        {ADDONS_DETAILS &&
                          ADDONS_DETAILS?.length !== 0 &&
                          ADDONS_DETAILS?.map((data) => {
                            return (
                              <div key={data?.options_id} className="p-item">
                                <FormControl className={"mb-md-3 mb-0 w-100"}>
                                  <h4
                                    className="mb-0"
                                    style={
                                      data?.products_options_type == 1
                                        ? {
                                            lineHeight: "0px",
                                            marginTop: "2px",
                                          }
                                        : {}
                                    }
                                  >
                                    {data?.products_options_name}
                                    {data?.free_modifier_quantity > 0 && (
                                      <span style={{ fontSize: "13px" }}>
                                        ({data?.free_modifier_quantity} Free
                                        option available)
                                      </span>
                                    )}
                                    {firstLevelErrorHandlerArr?.length > 0 &&
                                    firstLevelErrorHandlerArr?.includes(
                                      data?.options_id
                                    ) ? (
                                      <span
                                        style={{
                                          fontSize: "14px",
                                          color: "red",
                                          fontWeight: "bold",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        {"(Please select item)"}
                                      </span>
                                    ) : null}
                                  </h4>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      color: "#996F00",
                                      fontWeight: "bold",
                                      marginTop: "0px",
                                    }}
                                  >
                                    {/* {data?.products_options_type == 3 && */}
                                    {renderCheckboxRequiredOpt(data)}
                                  </p>
                                  {data?.products_options_type == 1 && (
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        color: "#000",
                                      }}
                                    >
                                      (additional charges may apply)
                                    </p>
                                  )}

                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                  >
                                    <div className="build-own-li product-data-sec  product-data-nth-child">
                                      {/* CHECKBOX START */}
                                      {data?.products_options_type == 3 && (
                                        <div className="">
                                          {data?.optionValues &&
                                            data?.optionValues.length &&
                                            data?.optionValues.map(
                                              (checkOptValue, index) => {
                                                return (
                                                  <Fragment key={index}>
                                                    <div className="nested-wrapper-sec d-flex justify-content-between align-items-center mb-2 mb-sm-3">
                                                      <FormControlLabel
                                                        value="female"
                                                        control={
                                                          <Checkbox
                                                            sx={{
                                                              color: "",
                                                              "&.Mui-checked": {
                                                                color:
                                                                  "#F06F00",
                                                              },
                                                            }}
                                                            checked={addonsObj?.attributes?.some(
                                                              (el) =>
                                                                el?.options_values_id ==
                                                                checkOptValue?.options_values_id
                                                                  ? true
                                                                  : false
                                                            )}
                                                            onChange={(e) =>
                                                              handelChangeCheckbox(
                                                                e.target
                                                                  .checked,
                                                                data,
                                                                checkOptValue
                                                              )
                                                            }
                                                            disabled={handelDisableCheckbox(
                                                              data,
                                                              checkOptValue
                                                            )}
                                                          />
                                                        }
                                                        label={
                                                          <>
                                                            <div className="d-flex justify-content-between w-100 salad-wrapper">
                                                              <span>
                                                                {
                                                                  checkOptValue?.products_options_values_name
                                                                }
                                                              </span>
                                                            </div>

                                                            {RESTAURANTS?.is_display_modifier_quantity ===
                                                              1 &&
                                                              parseFloat(
                                                                checkOptValue?.options_values_price
                                                              )?.toFixed(2) >
                                                                0 && (
                                                                <span className="price fw-semibold">
                                                                  $
                                                                  {parseFloat(
                                                                    checkOptValue?.options_values_price
                                                                  )?.toFixed(2)}
                                                                </span>
                                                              )}
                                                          </>
                                                        }
                                                      />

                                                      <div className="d-flex align-items-center nested-price-wrapper">
                                                        {RESTAURANTS?.is_display_modifier_quantity ===
                                                          1 &&
                                                        data?.support_choice_quantities ? (
                                                          <div className="qty-wrap">
                                                            <button
                                                              type="button"
                                                              disabled={
                                                                addonsObj?.attributes?.some(
                                                                  (el) =>
                                                                    el?.options_values_id ==
                                                                    checkOptValue?.options_values_id
                                                                )
                                                                  ? false
                                                                  : true
                                                              }
                                                              className={
                                                                addonsObj?.attributes?.some(
                                                                  (el) =>
                                                                    el?.options_values_id ==
                                                                    checkOptValue?.options_values_id
                                                                )
                                                                  ? ""
                                                                  : "qty-button-disable"
                                                              }
                                                              onClick={() => {
                                                                if (
                                                                  addonsObj?.attributes?.find(
                                                                    (el) =>
                                                                      el?.options_values_id ==
                                                                      checkOptValue?.options_values_id
                                                                  )?.quantity >
                                                                  checkOptValue.min_choice_quantity
                                                                ) {
                                                                  handelChangeQty(
                                                                    "subtract",
                                                                    data,
                                                                    checkOptValue
                                                                  );
                                                                }
                                                              }}
                                                            >
                                                              <RemoveCircleOutlineOutlinedIcon />
                                                            </button>
                                                            <span
                                                              className={
                                                                addonsObj?.attributes?.some(
                                                                  (el) =>
                                                                    el?.options_values_id ==
                                                                    checkOptValue?.options_values_id
                                                                )
                                                                  ? "qty-count"
                                                                  : "qty-count qty-disable"
                                                              }
                                                            >
                                                              {addonsObj?.attributes?.find(
                                                                (el) =>
                                                                  el?.options_values_id ==
                                                                  checkOptValue?.options_values_id
                                                              )?.quantity ?? 0}
                                                            </span>
                                                            <button
                                                              type="button"
                                                              disabled={
                                                                addonsObj?.attributes?.some(
                                                                  (el) =>
                                                                    el?.options_values_id ==
                                                                    checkOptValue?.options_values_id
                                                                )
                                                                  ? false
                                                                  : true
                                                              }
                                                              className={
                                                                addonsObj?.attributes?.some(
                                                                  (el) =>
                                                                    el?.options_values_id ==
                                                                    checkOptValue?.options_values_id
                                                                )
                                                                  ? ""
                                                                  : "qty-button-disable"
                                                              }
                                                              onClick={() => {
                                                                if (
                                                                  checkOptValue.max_choice_quantity ===
                                                                    0 ||
                                                                  addonsObj?.attributes?.find(
                                                                    (el) =>
                                                                      el?.options_values_id ==
                                                                      checkOptValue?.options_values_id
                                                                  )?.quantity <
                                                                    checkOptValue.max_choice_quantity
                                                                ) {
                                                                  handelChangeQty(
                                                                    "addition",
                                                                    data,
                                                                    checkOptValue
                                                                  );
                                                                }
                                                              }}
                                                            >
                                                              <ControlPointOutlinedIcon />
                                                            </button>
                                                          </div>
                                                        ) : (
                                                          <>
                                                            {parseFloat(
                                                              checkOptValue?.options_values_price
                                                            )?.toFixed(2) >
                                                            0 ? (
                                                              <span className="price">
                                                                $
                                                                {parseFloat(
                                                                  checkOptValue?.options_values_price
                                                                )?.toFixed(2)}
                                                              </span>
                                                            ) : null}
                                                          </>
                                                        )}
                                                        {/* QUANTITY OF CHECKBOX */}
                                                        {checkOptValue?.second_level_options &&
                                                        checkOptValue
                                                          ?.second_level_options
                                                          ?.length !== 0 ? (
                                                          <>
                                                            {showSecondLevelBlock &&
                                                            activeSecondLevelBlock?.products_attributes_id ==
                                                              checkOptValue.products_attributes_id ? (
                                                              <ExpandLessIcon
                                                                onClick={() => {
                                                                  if (
                                                                    addonsObj?.attributes?.some(
                                                                      (gt) =>
                                                                        gt?.options_values_id ==
                                                                        checkOptValue?.options_values_id
                                                                    )
                                                                  ) {
                                                                    handelShowSecondLevelModiefire(
                                                                      data,
                                                                      checkOptValue,
                                                                      "check",
                                                                      false
                                                                    );
                                                                  }
                                                                }}
                                                                style={
                                                                  addonsObj?.attributes?.some(
                                                                    (gt) =>
                                                                      gt?.options_values_id ==
                                                                      checkOptValue?.options_values_id
                                                                  )
                                                                    ? {
                                                                        cursor:
                                                                          "pointer",
                                                                      }
                                                                    : {
                                                                        color:
                                                                          "lightGray",
                                                                        cursor:
                                                                          "not-allowed",
                                                                      }
                                                                }
                                                              />
                                                            ) : (
                                                              <ExpandMoreIcon
                                                                onClick={() => {
                                                                  if (
                                                                    addonsObj?.attributes?.some(
                                                                      (gt) =>
                                                                        gt?.options_values_id ==
                                                                        checkOptValue?.options_values_id
                                                                    )
                                                                  ) {
                                                                    handelShowSecondLevelModiefire(
                                                                      data,
                                                                      checkOptValue,
                                                                      "check",
                                                                      true
                                                                    );
                                                                  }
                                                                }}
                                                                style={
                                                                  addonsObj?.attributes?.some(
                                                                    (gt) =>
                                                                      gt?.options_values_id ==
                                                                      checkOptValue?.options_values_id
                                                                  )
                                                                    ? {
                                                                        cursor:
                                                                          "pointer",
                                                                      }
                                                                    : {
                                                                        color:
                                                                          "lightGray",
                                                                        cursor:
                                                                          "not-allowed",
                                                                      }
                                                                }
                                                              />
                                                            )}
                                                          </>
                                                        ) : (
                                                          <span
                                                            style={{
                                                              width: "15px",
                                                              // marginLeft: "7px",
                                                              marginLeft:
                                                                "18px",
                                                            }}
                                                          />
                                                        )}
                                                      </div>
                                                    </div>

                                                    {checkOptValue?.second_level_options &&
                                                      checkOptValue
                                                        ?.second_level_options
                                                        ?.length !== 0 && (
                                                        <>
                                                          {showSecondLevelBlock &&
                                                            activeSecondLevelBlock?.products_attributes_id ==
                                                              checkOptValue?.products_attributes_id && (
                                                              <Fragment>
                                                                <div className="selection-required">
                                                                  {renderSelectionUI(
                                                                    checkOptValue
                                                                  )}

                                                                  <div
                                                                    className="d-flex justify-content-between edit-selection"
                                                                    onClick={() =>
                                                                      showSecondLevelBlockUi(
                                                                        data,
                                                                        checkOptValue
                                                                      )
                                                                    }
                                                                  >
                                                                    <h6 className="mb-0 text-decoration-underline">
                                                                      Add
                                                                      selections
                                                                    </h6>
                                                                    <ArrowForwardIosIcon />
                                                                  </div>
                                                                </div>
                                                              </Fragment>
                                                            )}
                                                        </>
                                                      )}
                                                  </Fragment>
                                                );
                                              }
                                            )}
                                        </div>
                                      )}
                                      {/* CHECKBOX END */}

                                      {/*RADIO UI START*/}
                                      {data?.products_options_type == 2 && (
                                        <div className="">
                                          <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            onChange={(e) =>
                                              handelChangeRadio(
                                                e.target.value,
                                                data
                                              )
                                            }
                                          >
                                            {data?.optionValues &&
                                              data?.optionValues?.length &&
                                              data?.optionValues?.map(
                                                (radioOptValue, index) => {
                                                  return (
                                                    <Fragment key={index}>
                                                      <div className="nested-wrapper-sec d-flex justify-content-between align-items-center mb-2 mb-sm-3">
                                                        <FormControlLabel
                                                          value={JSON.stringify(
                                                            radioOptValue
                                                          )}
                                                          control={
                                                            <Radio
                                                              name={
                                                                data?.products_options_name
                                                              }
                                                              checked={addonsObj?.attributes?.some(
                                                                (el) =>
                                                                  el?.options_values_id ==
                                                                    radioOptValue?.options_values_id &&
                                                                  el?.options_id ===
                                                                    data?.options_id
                                                                    ? true
                                                                    : false
                                                              )}
                                                              // disabled={handelDisableRadio(
                                                              //   data,
                                                              //   radioOptValue
                                                              // )}
                                                              sx={{
                                                                color: "",
                                                                "&.Mui-checked":
                                                                  {
                                                                    color:
                                                                      "#F06F00",
                                                                  },
                                                              }}
                                                            />
                                                          }
                                                          label={
                                                            <>
                                                              <div className="d-flex justify-content-between w-100 salad-wrapper">
                                                                <span>
                                                                  {/* {
                                                                  activeSecondLevelBlock?.products_attributes_id
                                                                }{" "}
                                                                ==
                                                                {
                                                                  radioOptValue?.products_attributes_id
                                                                } */}
                                                                  {
                                                                    radioOptValue?.products_options_values_name
                                                                  }
                                                                </span>
                                                              </div>
                                                              {RESTAURANTS?.is_display_modifier_quantity ===
                                                                1 &&
                                                                parseFloat(
                                                                  radioOptValue?.options_values_price
                                                                )?.toFixed(2) >
                                                                  0 && (
                                                                  <span className="price fw-semibold">
                                                                    $
                                                                    {parseFloat(
                                                                      radioOptValue?.options_values_price
                                                                    )?.toFixed(
                                                                      2
                                                                    )}
                                                                  </span>
                                                                )}
                                                            </>
                                                          }
                                                        />
                                                        <div className="d-flex align-items-center nested-price-wrapper">
                                                          {RESTAURANTS?.is_display_modifier_quantity ===
                                                            1 &&
                                                          data?.support_choice_quantities ? (
                                                            <div className="qty-wrap">
                                                              <button
                                                                type="button"
                                                                disabled={
                                                                  addonsObj?.attributes?.some(
                                                                    (el) =>
                                                                      el?.options_values_id ==
                                                                      radioOptValue?.options_values_id
                                                                  )
                                                                    ? false
                                                                    : true
                                                                }
                                                                className={
                                                                  addonsObj?.attributes?.some(
                                                                    (el) =>
                                                                      el?.options_values_id ==
                                                                      radioOptValue?.options_values_id
                                                                  )
                                                                    ? ""
                                                                    : "qty-button-disable"
                                                                }
                                                                onClick={() => {
                                                                  if (
                                                                    addonsObj?.attributes?.find(
                                                                      (el) =>
                                                                        el?.options_values_id ==
                                                                        radioOptValue?.options_values_id
                                                                    )
                                                                      ?.quantity >
                                                                    1
                                                                  ) {
                                                                    handelChangeQty(
                                                                      "subtract",
                                                                      data,
                                                                      radioOptValue
                                                                    );
                                                                  }
                                                                }}
                                                              >
                                                                <RemoveCircleOutlineOutlinedIcon />
                                                              </button>
                                                              <span
                                                                className={
                                                                  addonsObj?.attributes?.some(
                                                                    (el) =>
                                                                      el?.options_values_id ==
                                                                      radioOptValue?.options_values_id
                                                                  )
                                                                    ? "qty-count"
                                                                    : "qty-count qty-disable"
                                                                }
                                                              >
                                                                {addonsObj?.attributes?.find(
                                                                  (el) =>
                                                                    el?.options_values_id ==
                                                                    radioOptValue?.options_values_id
                                                                )?.quantity ??
                                                                  0}
                                                              </span>
                                                              <button
                                                                type="button"
                                                                disabled={
                                                                  addonsObj?.attributes?.some(
                                                                    (el) =>
                                                                      el?.options_values_id ==
                                                                      radioOptValue?.options_values_id
                                                                  )
                                                                    ? false
                                                                    : true
                                                                }
                                                                className={
                                                                  addonsObj?.attributes?.some(
                                                                    (el) =>
                                                                      el?.options_values_id ==
                                                                      radioOptValue?.options_values_id
                                                                  )
                                                                    ? ""
                                                                    : "qty-button-disable"
                                                                }
                                                                onClick={() => {
                                                                  if (
                                                                    addonsObj?.attributes?.find(
                                                                      (el) =>
                                                                        el?.options_values_id ==
                                                                        radioOptValue?.options_values_id
                                                                    )
                                                                      ?.quantity <
                                                                    99
                                                                  ) {
                                                                    handelChangeQty(
                                                                      "addition",
                                                                      data,
                                                                      radioOptValue
                                                                    );
                                                                  }
                                                                }}
                                                              >
                                                                <ControlPointOutlinedIcon />
                                                              </button>
                                                            </div>
                                                          ) : (
                                                            <>
                                                              {parseFloat(
                                                                radioOptValue?.options_values_price
                                                              )?.toFixed(2) >
                                                              0 ? (
                                                                <span className="price">
                                                                  $
                                                                  {parseFloat(
                                                                    radioOptValue?.options_values_price
                                                                  )?.toFixed(2)}
                                                                </span>
                                                              ) : null}
                                                            </>
                                                          )}
                                                          {/* QUANTITY OF RADIO */}
                                                          {radioOptValue?.second_level_options &&
                                                          radioOptValue
                                                            ?.second_level_options
                                                            ?.length !== 0 ? (
                                                            <>
                                                              {showSecondLevelBlock &&
                                                              activeSecondLevelBlock?.products_attributes_id ==
                                                                radioOptValue?.products_attributes_id ? (
                                                                <ExpandLessIcon
                                                                  onClick={() => {
                                                                    if (
                                                                      addonsObj?.attributes?.some(
                                                                        (gt) =>
                                                                          gt?.options_values_id ==
                                                                          radioOptValue?.options_values_id
                                                                      )
                                                                    ) {
                                                                      handelShowSecondLevelModiefire(
                                                                        data,
                                                                        radioOptValue,
                                                                        "radioType",
                                                                        false
                                                                      );
                                                                    }
                                                                  }}
                                                                  style={
                                                                    addonsObj?.attributes?.some(
                                                                      (gt) =>
                                                                        gt?.options_values_id ==
                                                                        radioOptValue?.options_values_id
                                                                    )
                                                                      ? {
                                                                          cursor:
                                                                            "pointer",
                                                                        }
                                                                      : {
                                                                          cursor:
                                                                            "not-allowed",
                                                                          color:
                                                                            "lightGray",
                                                                        }
                                                                  }
                                                                />
                                                              ) : (
                                                                <ExpandMoreIcon
                                                                  onClick={() => {
                                                                    if (
                                                                      addonsObj?.attributes?.some(
                                                                        (gt) =>
                                                                          gt?.options_values_id ==
                                                                          radioOptValue?.options_values_id
                                                                      )
                                                                    ) {
                                                                      handelShowSecondLevelModiefire(
                                                                        data,
                                                                        radioOptValue,
                                                                        "radioType",
                                                                        true
                                                                      );
                                                                    }
                                                                  }}
                                                                  style={
                                                                    addonsObj?.attributes?.some(
                                                                      (gt) =>
                                                                        gt?.options_values_id ==
                                                                        radioOptValue?.options_values_id
                                                                    )
                                                                      ? {
                                                                          cursor:
                                                                            "pointer",
                                                                        }
                                                                      : {
                                                                          cursor:
                                                                            "not-allowed",
                                                                          color:
                                                                            "lightGray",
                                                                        }
                                                                  }
                                                                />
                                                              )}
                                                            </>
                                                          ) : (
                                                            <span
                                                              style={{
                                                                width: "15px",
                                                                // marginLeft: "7px",
                                                                marginLeft:
                                                                  "18px",
                                                              }}
                                                            />
                                                          )}
                                                        </div>
                                                      </div>

                                                      {radioOptValue?.second_level_options &&
                                                        radioOptValue
                                                          ?.second_level_options
                                                          ?.length !== 0 && (
                                                          <>
                                                            {showSecondLevelBlock &&
                                                              activeSecondLevelBlock?.products_attributes_id ==
                                                                radioOptValue?.products_attributes_id && (
                                                                <Fragment>
                                                                  <div className="selection-required">
                                                                    {renderSelectionUI(
                                                                      radioOptValue
                                                                    )}

                                                                    <div
                                                                      className="d-flex justify-content-between edit-selection"
                                                                      onClick={() =>
                                                                        showSecondLevelBlockUi(
                                                                          data,
                                                                          radioOptValue
                                                                        )
                                                                      }
                                                                    >
                                                                      <h6 className="mb-0 text-decoration-underline">
                                                                        Add
                                                                        selections
                                                                      </h6>
                                                                      <ArrowForwardIosIcon />
                                                                    </div>
                                                                  </div>
                                                                </Fragment>
                                                              )}
                                                          </>
                                                        )}
                                                    </Fragment>
                                                  );
                                                }
                                              )}
                                          </RadioGroup>
                                        </div>
                                      )}
                                      {/*RADIO UI END*/}

                                      {/* TEST AREA START */}
                                      {data?.products_options_type == 1 && (
                                        <>
                                          {data?.optionValues &&
                                            data?.optionValues?.length &&
                                            data?.optionValues?.map(
                                              (textOptValue, index) => {
                                                return (
                                                  <Fragment key={index}>
                                                    <div
                                                      className={
                                                        textOptValue
                                                          ?.second_level_options
                                                          ?.length !== 0
                                                          ? "d-flex align-item-center"
                                                          : ""
                                                      }
                                                    >
                                                      <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        placeholder="Add Special instructions"
                                                        style={{
                                                          resize: "none",
                                                        }}
                                                        onChange={(e) =>
                                                          handelChangeText(
                                                            e,
                                                            data,
                                                            textOptValue
                                                          )
                                                        }
                                                        value={
                                                          addonsObj?.attributes?.find(
                                                            (el) =>
                                                              el?.options_values_id ==
                                                              textOptValue?.options_values_id
                                                          )?.options_value_text
                                                        }
                                                      />
                                                      {textOptValue?.second_level_options &&
                                                      textOptValue
                                                        ?.second_level_options
                                                        ?.length !== 0 ? (
                                                        <>
                                                          {showSecondLevelBlock &&
                                                          activeSecondLevelBlock?.products_attributes_id ==
                                                            textOptValue.products_attributes_id ? (
                                                            <ExpandLessIcon
                                                              onClick={() => {
                                                                if (
                                                                  addonsObj?.attributes?.some(
                                                                    (gt) =>
                                                                      gt?.options_values_id ==
                                                                      textOptValue?.options_values_id
                                                                  )
                                                                ) {
                                                                  handelShowSecondLevelModiefire(
                                                                    data,
                                                                    textOptValue,
                                                                    "testType",
                                                                    false
                                                                  );
                                                                }
                                                              }}
                                                              style={
                                                                addonsObj?.attributes?.some(
                                                                  (gt) =>
                                                                    gt?.options_values_id ==
                                                                    textOptValue?.options_values_id
                                                                )
                                                                  ? {
                                                                      cursor:
                                                                        "pointer",
                                                                    }
                                                                  : {
                                                                      cursor:
                                                                        "not-allowed",
                                                                      color:
                                                                        "lightGray",
                                                                    }
                                                              }
                                                            />
                                                          ) : (
                                                            <ExpandMoreIcon
                                                              onClick={() => {
                                                                if (
                                                                  addonsObj?.attributes?.some(
                                                                    (gt) =>
                                                                      gt?.options_values_id ==
                                                                      textOptValue?.options_values_id
                                                                  )
                                                                ) {
                                                                  handelShowSecondLevelModiefire(
                                                                    data,
                                                                    textOptValue,
                                                                    "testType",
                                                                    false
                                                                  );
                                                                }
                                                              }}
                                                              style={
                                                                addonsObj?.attributes?.some(
                                                                  (gt) =>
                                                                    gt?.options_values_id ==
                                                                    textOptValue?.options_values_id
                                                                )
                                                                  ? {
                                                                      cursor:
                                                                        "pointer",
                                                                    }
                                                                  : {
                                                                      cursor:
                                                                        "not-allowed",
                                                                      color:
                                                                        "lightGray",
                                                                    }
                                                              }
                                                            />
                                                          )}
                                                        </>
                                                      ) : (
                                                        <span
                                                          style={{
                                                            width: "15px",
                                                            marginLeft: "7px",
                                                          }}
                                                        />
                                                      )}
                                                    </div>
                                                    {textOptValue?.second_level_options &&
                                                      textOptValue
                                                        ?.second_level_options
                                                        ?.length !== 0 && (
                                                        <>
                                                          {showSecondLevelBlock &&
                                                            activeSecondLevelBlock?.products_attributes_id ==
                                                              textOptValue.products_attributes_id && (
                                                              <Fragment>
                                                                <div className="selection-required">
                                                                  {renderSelectionUI(
                                                                    textOptValue
                                                                  )}

                                                                  <div
                                                                    className="d-flex justify-content-between edit-selection"
                                                                    onClick={() =>
                                                                      showSecondLevelBlockUi(
                                                                        data,
                                                                        textOptValue
                                                                      )
                                                                    }
                                                                  >
                                                                    <h6 className="mb-0 text-decoration-underline">
                                                                      Add
                                                                      selections
                                                                    </h6>
                                                                    <ArrowForwardIosIcon />
                                                                  </div>
                                                                </div>
                                                              </Fragment>
                                                            )}
                                                        </>
                                                      )}
                                                  </Fragment>
                                                );
                                              }
                                            )}
                                        </>
                                      )}
                                      {/* TEST AREA END */}

                                      {/* DROP DOWN UI START */}
                                      {data?.products_options_type == 0 && (
                                        <Fragment>
                                          <DropdownButton
                                            className="form-control p-0"
                                            title={
                                              <h6 className="d-flex justify-content-between w-100 pe-3 mb-0 h-100 align-items-center ">
                                                {renderDropdownTitle(
                                                  data,
                                                  addonsObj
                                                )}
                                              </h6>
                                            }
                                            id="dropdown-menu-align-right"
                                            onSelect={(event) =>
                                              handelChangeDropdown(data, event)
                                            }
                                          >
                                            {data?.optionValues &&
                                              data?.optionValues?.length &&
                                              data?.optionValues?.map(
                                                (dropOptValue) => {
                                                  return (
                                                    <Fragment
                                                      key={
                                                        dropOptValue?.products_attributes_id
                                                      }
                                                    >
                                                      <Dropdown.Item
                                                        eventKey={JSON.stringify(
                                                          dropOptValue
                                                        )}
                                                        active={addonsObj?.attributes?.some(
                                                          (el) =>
                                                            el?.options_values_id ==
                                                            dropOptValue?.options_values_id
                                                              ? true
                                                              : false
                                                        )}
                                                      >
                                                        {
                                                          dropOptValue?.products_options_values_name
                                                        }
                                                        {parseFloat(
                                                          dropOptValue?.options_values_price
                                                        )?.toFixed(2) > 0 &&
                                                          dropOptValue?.options_values_price && (
                                                            <span
                                                              style={{
                                                                float: "right",
                                                              }}
                                                            >
                                                              $
                                                              {parseFloat(
                                                                dropOptValue?.options_values_price
                                                              )?.toFixed(2)}
                                                            </span>
                                                          )}
                                                      </Dropdown.Item>
                                                    </Fragment>
                                                  );
                                                }
                                              )}
                                          </DropdownButton>
                                          {/***  WE ARE NOT IMPLEMENTING SECOND LEVEL FOR DROPDOWN
                                       DISCUSSED WITH SAURABH DAVE AS DISCUSSES THIS CASE DO NOT PRODUCE ****/}

                                          {/* <Fragment>
                                      <div className="selection-required">
                                        <div className="select-item border-bottom pb-2 mb-2">
                                          <span>Dressing Dummy</span>
                                          <small>Bleu Cheese</small>
                                        </div>

                                        <div
                                          className="d-flex justify-content-between edit-selection"
                                          // onClick={() => handelEditSelection()}
                                        >
                                          <h6 className="mb-0 text-decoration-underline">
                                            Add selections
                                          </h6>
                                          <ArrowForwardIosIcon />
                                        </div>
                                      </div>
                                    </Fragment> */}
                                          {/***  WE ARE NOT IMPLEMENTING SECOND LEVEL FOR DROPDOWN
                                       DISCUSSED WITH SAURABH DAVE AS DISCUSSES THIS CASE DO NOT PRODUCE ****/}
                                        </Fragment>
                                      )}
                                      {/* DROP DOWN UI END */}
                                    </div>
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            );
                          })}

                        {activeNestedMode === "secondLevel" && (
                          <SecondLevelBlock
                            setVisibleSecondLevelBlock={
                              setVisibleSecondLevelBlock
                            }
                            secondLevelData={activeSecondLevelBlock}
                            addonsObj={addonsObj}
                            setAddonsObj={setAddonsObj}
                            setTemp={setTemp}
                            temp={temp}
                            setErrorHandler={setErrorHandler}
                            errorHandlerArr={errorHandlerArr}
                            RESTAURANTS={RESTAURANTS}
                            ADDONS_DETAILS={ADDONS_DETAILS}
                            //3rd Level
                            showThirdLevelBlock={showThirdLevelBlock}
                            setShowThirdLevelBlock={setShowThirdLevelBlock}
                            showThirdLevelBlockUi={showThirdLevelBlockUi}
                            activeThirdLevelBlock={activeThirdLevelBlock}
                            handelShowThirdLevelModiefire={
                              handelShowThirdLevelModiefire
                            }
                            handelShowThirdLevelModifureOnEvent={
                              handelShowThirdLevelModifureOnEvent
                            }
                            setActiveNestedMode={setActiveNestedMode}
                          />
                        )}
                        {activeNestedMode === "thirdLevel" && (
                          <ThirdLevelBlock
                            setVisibleThirdLevelBlock={
                              setVisibleThirdLevelBlock
                            }
                            secondLevelData={activeSecondLevelBlock}
                            thirdLevelData={activeThirdLevelBlock}
                            addonsObj={addonsObj}
                            setAddonsObj={setAddonsObj}
                            setTemp={setTemp}
                            temp={temp}
                            ADDONS_DETAILS={ADDONS_DETAILS}
                            setErrorHandler={setErrorHandler}
                            errorHandlerArr={errorHandlerArr}
                            RESTAURANTS={RESTAURANTS}
                            handelShowFourthLevelModiefire={
                              handelShowFourthLevelModiefire
                            }
                            showFourthLevelBlockUi={showFourthLevelBlockUi}
                            showFourthLevelBlock={showFourthLevelBlock}
                            setShowFourthLevelBlock={setShowFourthLevelBlock}
                            activeFourthLevelBlock={activeFourthLevelBlock}
                            setActiveNestedMode={setActiveNestedMode}
                          />
                        )}
                        {activeNestedMode === "fourthLevel" && (
                          <FourthLevelBlock
                            setVisibleThirdLevelBlock={
                              setVisibleThirdLevelBlock
                            }
                            secondLevelData={activeSecondLevelBlock}
                            thirdLevelData={activeThirdLevelBlock}
                            fourthLevelData={activeFourthLevelBlock}
                            addonsObj={addonsObj}
                            setAddonsObj={setAddonsObj}
                            setTemp={setTemp}
                            temp={temp}
                            ADDONS_DETAILS={ADDONS_DETAILS}
                            setErrorHandler={setErrorHandler}
                            errorHandlerArr={errorHandlerArr}
                            RESTAURANTS={RESTAURANTS}
                            handelShowFourthLevelModiefire={
                              handelShowFourthLevelModiefire
                            }
                            setVisibleFourthLevelBlock={
                              setVisibleFourthLevelBlock
                            }
                            showFourthLevelBlockUi={showFourthLevelBlockUi}
                            showFourthLevelBlock={showFourthLevelBlock}
                            setShowFourthLevelBlock={setShowFourthLevelBlock}
                            activeFourthLevelBlock={activeFourthLevelBlock}
                            setActiveNestedMode={setActiveNestedMode}
                          />
                        )}
                      </Form>
                    )}
                  </div>
                </Modal.Body>
              )}
            </div>
            {!loader && activeNestedMode === "firstLevel" && (
              <Modal.Footer className="justify-content-center border-0 modal-bottom-actions ">
                <div className="product-footer d-flex">
                  <div className="quantity-actions d-flex align-items-center">
                    <Button
                      type="button"
                      title={
                        addonsObj?.customers_basket_quantity == 1
                          ? "Min basket quantity is 1"
                          : ""
                      }
                      onClick={() => {
                        if (addonsObj?.customers_basket_quantity > 1) {
                          handelAddBasketQuantity("sub");
                        }
                      }}
                      style={
                        addonsObj?.customers_basket_quantity == 1
                          ? {
                              cursor: "not-allowed",
                            }
                          : { cursor: "pointer" }
                      }
                    >
                      <span
                        style={
                          addonsObj?.customers_basket_quantity == 1
                            ? { color: "lightGray " }
                            : { color: "#000" }
                        }
                      >
                        -
                      </span>
                    </Button>
                    {activeQtyInput ? (
                      <Form.Control
                        type="text"
                        value={addonsObj?.customers_basket_quantity}
                        onChange={(e) => {
                          setAddonsObj((prevState) => ({
                            ...prevState,
                            customers_basket_quantity: e.target.value,
                          }));
                        }}
                        maxLength={5}
                        onKeyPress={(e) => {
                          floatInput(e);
                        }}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        value={addonsObj?.customers_basket_quantity}
                        readOnly={true}
                        style={{ cursor: "not-allowed" }}
                      />
                    )}
                    <Button
                      type="button"
                      title={
                        addonsObj?.customers_basket_quantity == 99
                          ? "You can't add more then 15 quantity of a product."
                          : ""
                      }
                      style={
                        addonsObj?.customers_basket_quantity == 99
                          ? { cursor: "not-allowed", color: "lightGray" }
                          : { cursor: "pointer" }
                      }
                      onClick={() => {
                        if (addonsObj?.customers_basket_quantity < 99) {
                          handelAddBasketQuantity("add");
                        }
                      }}
                    >
                      <span
                        style={
                          addonsObj?.customers_basket_quantity == 99
                            ? { color: "lightGray " }
                            : { color: "#000" }
                        }
                      >
                        +
                      </span>
                    </Button>
                  </div>

                  <Button
                    className="common-btn py-3"
                    type="button"
                    onClick={() => {
                      if (addonsObj?.customers_basket_quantity > 0) {
                        handelAddUpdateCart();
                      } else {
                        errorToast(
                          "Customers basket quantity field is required."
                        );
                      }
                    }}
                  >
                    <span className="d-flex justify-content-center align-items-center mobile-btn">
                      <span>
                        {cartView == 1 ? "Update" : "Add To"} Order{" "}
                        <span className="d-inline-block">
                          $ {renderAddToCartPriceCount()}{" "}
                        </span>
                      </span>
                      {addUpdateAttributeLoader && (
                        <Loader
                          className=""
                          Style={{
                            marginLeft: "20px",
                          }}
                        />
                      )}
                    </span>
                  </Button>
                </div>
              </Modal.Footer>
            )}
          </Modal>
        )}
      </div>
    </div>
  );
};

export default RestaurantsModal;
