import React, { Fragment, useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import menuIcon from "assets/images/menu-icon.svg";
import menuIconmobile from "assets/images/menu-iconmobile.svg";
import shoppingIcon from "assets/images/shopping-bag-icon.svg";
import Logo from "assets/images/logo-new.webp";
// import Logo from "assets/images/Fdd-logo-new-head.svg";
import NotificationSvg from "assets/images/notification.svg";
import NotificationWhiteSvg from "assets/images/bell_white.svg";
import ProfileSvg from "assets/images/profile.svg";
import ProfileWhiteSvg from "assets/images/user_white.svg";
import cartIcon from "assets/images/cart.svg";
import { useDispatch, useSelector } from "react-redux";
import HeaderNotification from "./Notification/HeaderNotification";
import {
  GET_HEADER_NOTIFICATION,
  GET_NOTIFICATION_EXIST,
  GET_NOTIFICATION_POPUP,
  GET_RESTAURANT_LIST_HEADER,
  SET_NOTIFICATION_READ,
  SHOW_BOTTOM_NAVIGATION_SEARCH,
} from "store/Action/HomeAction";
import HeaderCart from "./headerCart/HeaderCart";
import FloatingActionContainer from "components/shared/Floating/FloatingActionContainer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import Search from "./HeaderSearch/Search";
import {
  GET_SELECTED_RESTAURANT_DATA,
  GET_SELECT_CUISINES_SEARCH,
} from "store/Action/RasturantAction";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import NotFound from "./HeaderSearch/NotFound";
import {
  GET_CART_LIST,
  PREVENT_RESTAURANT_ID,
  PREVENT_RESTAURANT_NAME,
} from "store/Action/RasturantMenuAction";
import { successToast } from "utils/toastMessage";
import { trackEvent } from "utils/analytics";

const NativeHeader = ({
  classes,
  path,
  sidebarShow,
  handelSidebarOpen,
  handleToggleHeaderCart,
  setHeaderCart,
  headerCart,
  showHamburger,
  visibleFloatingMessage,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const localStorageToken = localStorage.getItem("access_token");

  // ALL SELECTORS
  const {
    NOTIFICATION_LIST,
    SHOE_NOTIFICATION_POPUP,
    NEW_USER_STATE,
    ALL_ADDRESS,
    CART_LIST,
    DEFAULT_ADDRESS,
    SHOW_TICKER_STATE,
    SHOW_SEARCH_SCREEN,
    RESTAURANTS_SEARCH_LIST,
    CUISINES,
    CUISINES_SELECT_SEARCH_STATE,
    ASAP_ADVANCE,
    ASAP_TIME,
    SERVICE_TYPE,
    ASAP_DATE_STATE,
    ASAP_ADVANCE_STATE,
    ASAP_TIME_STATE,
    SELECTED_RESTAURANT_DATA_STATE,
    GROUP_ID,
  } = useSelector((store) => ({
    NOTIFICATION_LIST: store?.HomeReducer?.headerNotification?.listing,
    SHOE_NOTIFICATION_POPUP: store?.HomeReducer?.showHeaderPopUp,
    NEW_USER_STATE: store?.AuthReducer?.newUserRegistered,
    ALL_ADDRESS: store.AddressReducer.allAddress,
    CART_LIST: store?.RasturantMenuReducer?.cartList ?? [],
    DEFAULT_ADDRESS: store.ProfileReducer.defaultAddress,
    SHOW_TICKER_STATE: store?.HomeReducer?.showTickerMessage ?? false,
    SHOW_SEARCH_SCREEN: store.HomeReducer.showSearchScreen,
    RESTAURANTS_SEARCH_LIST: store.HomeReducer.restaurantsOnSearch,
    CUISINES: store?.ResturantReducer?.cuisines,
    CUISINES_SELECT_SEARCH_STATE:
      store?.ResturantReducer?.searchCusinesArr ?? [],
    ASAP_DATE_STATE: store?.ResturantReducer?.asapDate,
    ASAP_TIME: store?.ResturantReducer?.timeSlot,
    ASAP_ADVANCE: store?.ResturantReducer?.asapAdvance,
    SERVICE_TYPE: store.ResturantReducer.serviceType,
    ASAP_ADVANCE_STATE: store?.ResturantReducer?.asapAdvance,
    ASAP_TIME_STATE: store?.ResturantReducer?.timeSlot,
    SELECTED_RESTAURANT_DATA_STATE:
      store?.ResturantReducer?.selectedRestaurantData ?? {},
    GROUP_ID: store?.RasturantMenuReducer?.groupId,
  }));

  const Token = localStorage.getItem("access_token");
  //var dynamicUrl = `/${SELECTED_RESTAURANT_DATA_STATE.city_name}/${SELECTED_RESTAURANT_DATA_STATE?.restro_name}`
  var dynamicUrl = `/${SELECTED_RESTAURANT_DATA_STATE?.slug}`;

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [showRedLabel, setShowRedLabel] = useState(0);
  const [restaurantsValue, setRestaurantsValue] = useState("");
  const [restaurantLoader, setRestaurantLoader] = useState(false);
  const [cusionsArr, setCusionsArr] = useState([]);
  const [cartLoading, setCartLoading] = useState(false);

  const handelSearch = (event) => {
    setRestaurantsValue(event.target.value);
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    let isPromoCodeApplied = localStorage.getItem("is_promo_code_applied");
    if (Token && isPromoCodeApplied) {
      const timeoutId = setTimeout(() => {
        if (isPromoCodeApplied) {
          console.log("isPromoCodeApplied", isPromoCodeApplied);
          successToast("Promo code has been applied");
          localStorage.removeItem("is_promo_code_applied");
        }
      }, 2000);
      return () => clearTimeout(timeoutId);
      /* successToast("Promo code has been applied");
      localStorage.removeItem("is_promo_code_applied"); */
    }
  }, [Token]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handelClickNotification = () => {
    // setNotificationPopup(!notificationPopup);
    dispatch(GET_NOTIFICATION_POPUP(!SHOE_NOTIFICATION_POPUP));
  };
  useEffect(() => {
    if (localStorageToken) {
      const params = { page: 0, pagesize: 4 };
      dispatch(GET_HEADER_NOTIFICATION({ params }, (loading, error) => {}));
    }
  }, [localStorageToken]);

  useEffect(() => {
    if (Token) {
      fetchNotificationExist();
    }
  }, [Token]);

  const fetchNotificationExist = () => {
    if (Token) {
      dispatch(
        GET_NOTIFICATION_EXIST({}, (res, loading, error) => {
          if (!error) {
            setShowRedLabel(res?.data?.is_unread_notification_exist);
          }
        })
      );
    }
  };

  const handelReadNotification = () => {
    const dataPayload = {};
    dispatch(
      SET_NOTIFICATION_READ(dataPayload, (loading, error) => {
        if (!error) {
          fetchNotificationExist();
        }
      })
    );
  };

  const handelOnSelectRestaurants = (item) => {
    // navigate(`/restaurants-menu/${item?.id}`);
    dispatch(GET_SELECTED_RESTAURANT_DATA(item));
    navigate(`/${item?.slug}?q=fd`);
  };

  const handelSelectCuisines = (item) => {
    // const temp = [...cusionsArr]
    const temp = [...CUISINES_SELECT_SEARCH_STATE];
    if (CUISINES_SELECT_SEARCH_STATE.some((el) => el == item?.id)) {
      let res = temp.filter((el) => {
        if (el !== item?.id) {
          return el;
        }
      });

      // setCusionsArr(res)
      dispatch(GET_SELECT_CUISINES_SEARCH(res));
      navigate("/restaurants");
    } else {
      dispatch(GET_SELECT_CUISINES_SEARCH([...temp, item.id]));
      navigate("/restaurants");
      // setCusionsArr([...temp, item.id])
    }
    dispatch(SHOW_BOTTOM_NAVIGATION_SEARCH(!SHOW_SEARCH_SCREEN));
  };

  // useEffect(() => {
  //   dispatch(GET_SELECT_CUISINES_SEARCH(cusionsArr))
  //   // navigate('/restaurants')
  // }, [cusionsArr])

  useEffect(() => {
    if (restaurantsValue) {
      if (Object.keys(ASAP_TIME).length) {
        var currentDate = moment(ASAP_DATE_STATE).format("YYYY-MM-DD");
        var time = ASAP_TIME?.time?.split("-");
        var Time = time[1]?.replace(/\s/g, "");
        // var currentTime = moment(Time, "h:mm:ss A").format("HH:mm:ss");
        var currentTime = moment(Time, "h:mm A").format("HH:mm");
      }
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (DEFAULT_ADDRESS?.entry_city) {
        const params = {
          lat_from: DEFAULT_ADDRESS?.address_lat,
          lng_from: DEFAULT_ADDRESS?.address_lng,
          keyword: restaurantsValue,
          asap_or_advance: ASAP_ADVANCE,
          service_type: SERVICE_TYPE,
          date: currentDate ?? "",
          time: currentTime ?? "",
          timezone: timeZone,
          city: DEFAULT_ADDRESS?.entry_city,
        };
        dispatch(
          GET_RESTAURANT_LIST_HEADER({ params }, (loading, error) => {
            setRestaurantLoader(loading);
          })
        );
      }
    }
  }, [restaurantsValue]);

  useEffect(() => {
    if (location.pathname !== dynamicUrl) {
      if (
        location.pathname !== "/checkout" &&
        location.pathname !== "/card-checkout"
      ) {
        handelCallCart();
      }
    }
  }, []);

  const handelCallCart = () => {
    if (ASAP_ADVANCE_STATE == 1) {
      if (DEFAULT_ADDRESS?.entry_city) {
        const params = {
          lat_from: DEFAULT_ADDRESS?.address_lat ?? 0,
          lng_from: DEFAULT_ADDRESS?.address_lng ?? 0,
          postcode: DEFAULT_ADDRESS?.entry_postcode ?? "",
          asap_or_advance: ASAP_ADVANCE_STATE,
          service_type: SERVICE_TYPE,
          city: DEFAULT_ADDRESS?.entry_city,
          group_id: GROUP_ID ?? "",
        };
        dispatch(
          GET_CART_LIST({ params }, (res, loading, error) => {
            setCartLoading(false);
            if (res?.restaurant) {
              dispatch(PREVENT_RESTAURANT_NAME(res?.restaurant?.name));
              dispatch(PREVENT_RESTAURANT_ID(res?.restaurant));
            }
          })
        );
      }
    } else {
      if (DEFAULT_ADDRESS?.entry_city) {
        const currentDate = moment(ASAP_DATE_STATE).format("YYYY-MM-DD");
        const time = ASAP_TIME_STATE?.time?.split("-");
        const Time = time[1]?.replace(/\s/g, "");
        const currentTime = moment(Time, "h:mm A").format("HH:mm");
        const params = {
          group_id: GROUP_ID,
          lat_from: DEFAULT_ADDRESS?.address_lat ?? 0,
          lng_from: DEFAULT_ADDRESS?.address_lng ?? 0,
          postcode: DEFAULT_ADDRESS?.entry_postcode ?? "",
          asap_or_advance: ASAP_ADVANCE_STATE,
          date: currentDate,
          time: currentTime,
          service_type: SERVICE_TYPE,
          city: DEFAULT_ADDRESS?.entry_city,
          group_id: GROUP_ID ?? "",
        };
        dispatch(
          GET_CART_LIST({ params }, (res, loading, error) => {
            setCartLoading(false);
            if (res?.restaurant) {
              dispatch(PREVENT_RESTAURANT_NAME(res?.restaurant?.name));
              dispatch(PREVENT_RESTAURANT_ID(res?.restaurant));
            }
          })
        );
      }
    }
  };

  const [sticky, setSticky] = useState("");
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 20 ? "fixed-top" : "";
    setSticky(stickyClass);
  };

  const totalCartItem = () => {
    let total = 0;
    for (const cart of CART_LIST) {
      total += cart.customers_basket_quantity;
    }

    return total;
  };

  return (
    <div>
      <header
        className={classes}
        style={
          !path
            ? {}
            : {
                boxShadow: "0px 0.071rem 0.71rem 0px rgb(0 0 0 / 20%)",
                backgroundColor: "#fff",
                animation: "none",
              }
        }
      >
        {SHOW_TICKER_STATE && visibleFloatingMessage && (
          <FloatingActionContainer />
        )}
        <div
          className={
            showHamburger ? "front-header" : "front-header without-hamburger"
          }
        >
          <Container fluid>
            <div
              className={
                !path
                  ? "d-flex justify-content-between align-items-center front-header-sec"
                  : "d-flex  justify-content-between align-items-center"
              }
            >
              <div className="header-wrapper d-flex align-items-center">
                <div className="main-logo d-flex align-items-center 123">
                  {path || sidebarShow ? (
                    <Button
                      className="hamburger_btn d-inline-block me-sm-4 me-2 bg-transparent border-0 p-0"
                      onClick={() => handelSidebarOpen()}
                    >
                      <img
                        src={menuIcon}
                        alt="No_Image"
                        className="d-none d-md-inline-block for_desktop"
                      />
                      <img
                        src={menuIconmobile}
                        alt="No_Image"
                        className="d-inline-block d-md-none for_mobile"
                      />
                    </Button>
                  ) : (
                    <span className="d-inline-block me-sm-4 me-2 bg-transparent border-0 p-0" />
                  )}
                  {NEW_USER_STATE == "newUser" ? (
                    <img src={Logo} alt="No_Image" />
                  ) : (
                    <Link
                      to={
                        location.pathname == "/checkout" ? "/restaurants" : "/"
                      }
                      onClick={() =>
                        dispatch(SHOW_BOTTOM_NAVIGATION_SEARCH(false))
                      }
                    >
                      <img src={Logo} alt="No_Image" />
                    </Link>
                  )}
                </div>
              </div>

              <div className="search-login-wrapper d-flex align-items-center">
                {/* /RESTAURANT SEARCH/ */}
                <div
                  className={
                    SHOW_SEARCH_SCREEN
                      ? `search-reasturants position-relative me-lg-4 me-2 search-rest-mobile show-search`
                      : "search-reasturants position-relative me-lg-4 me-2 search-rest-mobile d-none "
                  }
                >
                  <Button
                    className="back-icon mobile_search_back d-block d-md-none"
                    onClick={() =>
                      dispatch(SHOW_BOTTOM_NAVIGATION_SEARCH(false))
                    }
                  >
                    <ArrowBackIcon />
                  </Button>
                  <Form className="position-relative mobile_search_ss">
                    <div className="position-relative">
                      <Form.Control
                        type="text"
                        placeholder="Search Restaurants"
                        value={restaurantsValue}
                        onChange={(e) => handelSearch(e)}
                      />
                      {restaurantsValue && (
                        <Button
                          className="cross-icon position-absolute translate-middle-y bg-transparent border-0 text-dark p-0"
                          onClick={() => setRestaurantsValue("")}
                        >
                          <ClearIcon className="" />
                        </Button>
                      )}
                      <Button className="search-icon position-absolute top-50 translate-middle-y bg-transparent border-0 text-dark p-0">
                        <SearchIcon className="" />
                      </Button>
                    </div>
                  </Form>
                  {restaurantsValue && (
                    <>
                      {RESTAURANTS_SEARCH_LIST?.length !== 0 ? (
                        <Search
                          restaurantList={RESTAURANTS_SEARCH_LIST}
                          loader={restaurantLoader}
                          handelOnSelectRestaurants={(item) =>
                            handelOnSelectRestaurants(item)
                          }
                        />
                      ) : (
                        <NotFound loader={restaurantLoader} />
                      )}
                    </>
                  )}

                  {!restaurantsValue && (
                    <div className="top-search-cuisines d-md-none d-block px-sm-4 px-3 mt-4">
                      <div className="d-flex justify-content-between align-item-center">
                        <h6>Cuisines</h6>
                        <p
                          className="mb-0 clear-search"
                          onClick={() => {
                            setCusionsArr([]);
                            dispatch(GET_SELECT_CUISINES_SEARCH([]));

                            dispatch(
                              SHOW_BOTTOM_NAVIGATION_SEARCH(!SHOW_SEARCH_SCREEN)
                            );
                          }}
                        >
                          Clear All
                        </p>
                      </div>
                      <ul>
                        {CUISINES &&
                          CUISINES?.length > 0 &&
                          CUISINES?.map((item, i) => {
                            return (
                              <li
                                className="search-sty justify-content-between"
                                key={item?.id}
                                onClick={() => handelSelectCuisines(item)}
                              >
                                <div>
                                  <RestaurantIcon />
                                  <span>{item?.title}</span>
                                </div>
                                {CUISINES_SELECT_SEARCH_STATE?.some(
                                  (el) => el == item?.id
                                ) ? (
                                  <CheckCircleIcon
                                    sx={{ float: "right", color: "#f06f00" }}
                                  />
                                ) : null}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  )}
                </div>
                {/* /RESTAURANT SEARCH/ */}
                <div>
                  {!path ? (
                    <div className="header-login-btn">
                      {!localStorageToken ? (
                        <>
                          <Link
                            to={`/sign-in?group_id=${GROUP_ID}`}
                            className="theme-btn sign-in-btn"
                            onClick={() => {
                              trackEvent("visit_Login_page", {
                                page_location: window.location.href,
                                page: "Home",
                                event_place: "Home page login button",
                                event_type: "click",
                              });
                            }}
                          >
                            Sign In
                          </Link>
                          <Link
                            to={`/sign-up?group_id=${GROUP_ID}`}
                            className="theme-btn"
                            onClick={() => {
                              trackEvent("visit_signup_page", {
                                page_location: window.location.href,
                                page: "Home",
                                event_place: "Home page signup button",
                                event_type: "click",
                              });
                            }}
                          >
                            Sign Up
                          </Link>
                        </>
                      ) : (
                        <>
                          <Button
                            className="header-btn1 me-sm-3 me-2"
                            onClick={() => handelClickNotification()}
                          >
                            <span
                              className="d-inline-block"
                              // style={{ zIndex: 9 }}
                            >
                              {sticky ? (
                                <img src={NotificationSvg} alt="No_Image" />
                              ) : (
                                <img
                                  src={NotificationWhiteSvg}
                                  alt="No_Image"
                                />
                              )}

                              {showRedLabel === 1 && (
                                <div className="notification_dot"></div>
                              )}
                            </span>

                            {SHOE_NOTIFICATION_POPUP && (
                              <HeaderNotification
                                handelReadNotification={handelReadNotification}
                              />
                            )}
                          </Button>
                          <Button className="header-btn1 me-sm-3 me-2">
                            <span
                              onClick={() => handelSidebarOpen()}
                              className="d-inline-block"
                            >
                              {sticky ? (
                                <img src={ProfileSvg} alt="No_Image" />
                              ) : (
                                <img src={ProfileWhiteSvg} alt="No_Image" />
                              )}
                            </span>
                          </Button>
                          {CART_LIST && CART_LIST?.length !== 0 && (
                            <Button className="header-btn1">
                              <span
                                onClick={() => handleToggleHeaderCart()}
                                className="d-inline-block"
                              >
                                <img src={cartIcon} alt="No_Image" />

                                {cartLoading ? (
                                  <i
                                    className="fa fa-circle-o-notch fa-spin"
                                    style={{
                                      fontSize: "24px",
                                      color: "#fff",
                                      marginLeft: "10px",
                                    }}
                                  ></i>
                                ) : (
                                  <div className="cart-header-radio">
                                    {CART_LIST?.length}
                                  </div>
                                )}
                              </span>
                              {headerCart && (
                                <div className="header-cart-notification">
                                  <HeaderCart
                                    handleHeaderCart={handleToggleHeaderCart}
                                    setHeaderCart={setHeaderCart}
                                  />
                                </div>
                              )}
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <>
                      {sidebarShow &&
                        location.pathname !== "/card-checkout" &&
                        location.pathname !== "/giftcard-checkout" && (
                          <Fragment>
                            <div
                              className="d-block header-cart"
                              style={{
                                cursor: `${
                                  location.pathname === "/checkout"
                                    ? "not-allowed"
                                    : "pointer"
                                }`,
                              }}
                              onClick={() => {
                                if (
                                  CART_LIST?.length &&
                                  location.pathname !== "/checkout"
                                ) {
                                  handleToggleHeaderCart();
                                }
                              }}
                            >
                              <img src={shoppingIcon} alt="No_Image" />
                              {cartLoading ? (
                                <i
                                  className="fa fa-circle-o-notch fa-spin"
                                  style={{
                                    fontSize: "24px",
                                    color: "#fff",
                                    marginLeft: "10px",
                                  }}
                                ></i>
                              ) : (
                                <>
                                  <span> {totalCartItem()}</span>
                                </>
                              )}
                            </div>

                            {headerCart && CART_LIST?.length !== 0 && (
                              <div className="header-cart-notification">
                                <HeaderCart
                                  handleHeaderCart={handleToggleHeaderCart}
                                  setHeaderCart={setHeaderCart}
                                />
                              </div>
                            )}
                          </Fragment>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </header>
    </div>
  );
};

export default NativeHeader;
