import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import Driver from "assets/images/driver.png";
// import PickUp from "assets/images/Pickup-rest.png";
import PickUp from "assets/images/svg/Pickup.svg";
// import DropOff from "assets/images/svg/Dropoff.svg";
import DropOff from "assets/images/svg/DropoffGreenNew.svg";
// import DropOff from "assets/images/home-icon-three.png";
import "./OrderTrack.css";
import "./OrderTrackNew.css";
import ReactHelmet from "components/shared/ReactHelmet/ReactHelmet";
import NotFound from "components/shared/Loader/NotFound";
import Logo from "assets/images/logo-new.webp";
import CheckBox from "assets/images/checkmark (1).png";
import { useDispatch } from "react-redux";
import { GET_ORDER_TRACKING } from "store/Action/Sidebar/OrderAction";
import moment from "moment";
import OrderDetail from "../orderDetail/OrderDetail";
import restaurantImg from "assets/images/cuisines8.jpg";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import {
  GET_DEFAULT_ADDRESS,
  GET_SHOW_RESTAURANT_CART,
} from "store/Action/HomeAction";
import { useGoogleMaps } from "helpers/GoogleMap/googleMapsService";

const OrderTrackNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const isMobileApp = query.get("is_mobile_app");

  // const { isLoaded, loadError } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: "AIzaSyCJOYrk0ia3CDwLnlPvUV6-LGPk9PcsiOw",
  //   version: "3.47",
  //   libraries: ["geometry", "places", "roads"],
  // });
  const { isLoaded, loadError } = useGoogleMaps("tracking");

  //********************* */

  const paths = window.location.pathname.split("/");
  let OrderId = paths[paths.length - 1];
  const isMobile = window.innerWidth <= 768;
  const mapStyles = {
    height: isMobile ? "100vh" : "100vh",
    width: "100%",
  };
  const previousDriverPositionRef = useRef(null);
  const [driverPosition, setDriverPosition] = useState(null);
  const [routePath, setRoutePath] = useState([]);
  const [orderDetail, setOrderDetails] = useState({});
  const [isApiLoading, setApiLoading] = useState(true);
  const [pickupHover, setPickupHover] = useState(false);
  const [dropoffHover, setDropoffHover] = useState(false);
  const [visiblePhoneNo, setVisiblePhoneNo] = useState(false);
  const [bottomPanelShow, setBottomPanelShow] = useState(false);
  const [infoWindowOpenPickupMobile, setInfoWindowOpenPickupMobile] =
    useState(false);
  const [infoWindowOpenDropOffMobile, setInfoWindowOpenDropOffMobile] =
    useState(false);
  const [previousDriverPosition, setPreviousDriverPosition] = useState(null);
  const [temp, setTemp] = useState(false);

  useEffect(() => {
    if (isMobileApp == "yes") {
      const handleBackButton = (event) => {
        event.preventDefault();
        if (window.history.length > 1) {
          navigate(-1);
        } else {
          if (window.confirm("Do you want to exit the app?")) {
            window.close();
          }
        }
      };

      document.addEventListener("backbutton", handleBackButton);

      return () => {
        document.removeEventListener("backbutton", handleBackButton);
      };
    }
  }, [navigate]);

  const handleTogglePanel = () => {
    setBottomPanelShow(!bottomPanelShow);
  };

  useEffect(() => {
    if (OrderId) {
      getOrderDetails("initialCall");
    }
  }, [OrderId]);

  useEffect(() => {
    // This will run after driverPosition has changed
    console.log("Driver Position Updated:", driverPosition);

    if (driverPosition !== null) {
    }
  }, [driverPosition]);

  const getOrderDetails = () => {
    dispatch(
      GET_ORDER_TRACKING(OrderId, async (res, loading, error) => {
        setApiLoading(loading);
        if (!error && res?.data) {
          const data = res.data;

          data.address = {
            address_lat: 45.552509,
            address_lng: -94.196804,
            entry_street_address: "26 32nd Ave S, St Cloud, MN 56301, USA",
            entry_street_name: "26 32nd Ave S",
            entry_postcode: "56301",
            entry_suburb: "26 32nd Ave S",
            entry_state: "Minnesota",
            entry_city: "St. Cloud",
          };

          console.log("DATA", data);

          setOrderDetails(data);

          const newDriverPosition = {
            lat: parseFloat(data?.driver_lat),
            lng: parseFloat(data?.driver_lng),
          };

          if (
            newDriverPosition?.lat !==
              previousDriverPositionRef?.current?.lat ||
            newDriverPosition?.lng !== previousDriverPositionRef?.current?.lng
          ) {
            previousDriverPositionRef.current = newDriverPosition;

            setPreviousDriverPosition(newDriverPosition);
            if (data?.driver_route) {
              const route = data?.driver_route?.map(([lat, lng]) => ({
                lat: parseFloat(lat),
                lng: parseFloat(lng),
              }));

              if (data?.order_status_id == 8 || data?.order_status_id == 10) {
                // Call the snap-to-road API
                // const snappedRoute = await fetchSnappedPath(route);
                // Call the directions API
                // const snappedRoute = await fetchRouteUsingDirectionsAPI(route);
                // setRoutePath(snappedRoute || route);
                setRoutePath(route);
              } else {
                setRoutePath(route);
              }
            }
          }

          // Set initial driver position if available
          if (data?.driver_lat && data?.driver_lng) {
            setDriverPosition(newDriverPosition);
          }
        }
      })
    );
  };

  // Fetch snapped path from Google Roads API
  // const fetchSnappedPath = async (path) => {
  //   const apiKey = "AIzaSyCJOYrk0ia3CDwLnlPvUV6-LGPk9PcsiOw";
  //   const pathParam = path.map(({ lat, lng }) => `${lat},${lng}`).join("|");
  //   const url = `https://roads.googleapis.com/v1/snapToRoads?path=${pathParam}&interpolate=true&key=${apiKey}`;
  //   try {
  //     const response = await fetch(url);
  //     const data = await response.json();
  //     if (
  //       data?.warningMessage ==
  //       "Input path is too sparse. You should provide a path where consecutive points are closer to each other. Refer to the 'path' parameter in Google Roads API documentation."
  //     ) {
  //       setRoutePath(path);
  //     } else {
  //       if (data?.snappedPoints) {
  //         return data.snappedPoints.map((point) => ({
  //           lat: point.location.latitude,
  //           lng: point.location.longitude,
  //         }));
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching snapped path:", error);
  //   }
  //   return null;
  // };

  // Fetch route using Google Maps Directions API
  // const fetchRouteUsingDirectionsAPI = async (waypoints) => {
  //   const apiKey = "AIzaSyCJOYrk0ia3CDwLnlPvUV6-LGPk9PcsiOw";
  //   if (waypoints.length < 2) {
  //     console.error("At least two points are required to fetch a route.");
  //     return waypoints;
  //   }

  //   const origin = waypoints[0];
  //   const destination = waypoints[waypoints.length - 1];
  //   const intermediateWaypoints = waypoints
  //     .slice(1, -1)
  //     .map(({ lat, lng }) => `${lat},${lng}`)
  //     .join("|");

  //   console.log(
  //     "origin",
  //     origin,
  //     "destination",
  //     destination,
  //     "intermediateWaypoints",
  //     intermediateWaypoints
  //   );

  //   const url = `https://maps.googleapis.com/maps/api/directions/json?origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&waypoints=${intermediateWaypoints}&key=${apiKey}`;

  //   try {
  //     const response = await fetch(url);
  //     console.log("response", response);
  //     const data = await response.json();
  //     console.log("data", data);
  //     if (data.status === "OK" && data.routes.length > 0) {
  //       const route = data.routes[0].overview_polyline;
  //       return decodePolyline(route.points);
  //     } else {
  //       console.error(
  //         "Error in Directions API response:",
  //         data.error_message || data.status
  //       );
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error("Error fetching route using Directions API:", error);
  //     return null;
  //   }
  // };

  // // Function to decode the polyline into an array of lat/lng objects
  // const decodePolyline = (encoded) => {
  //   let points = [];
  //   let index = 0,
  //     lat = 0,
  //     lng = 0;

  //   while (index < encoded.length) {
  //     let b,
  //       shift = 0,
  //       result = 0;

  //     do {
  //       b = encoded.charCodeAt(index++) - 63;
  //       result |= (b & 0x1f) << shift;
  //       shift += 5;
  //     } while (b >= 0x20);

  //     let deltaLat = result & 1 ? ~(result >> 1) : result >> 1;
  //     lat += deltaLat;

  //     shift = 0;
  //     result = 0;
  //     do {
  //       b = encoded.charCodeAt(index++) - 63;
  //       result |= (b & 0x1f) << shift;
  //       shift += 5;
  //     } while (b >= 0x20);

  //     let deltaLng = result & 1 ? ~(result >> 1) : result >> 1;
  //     lng += deltaLng;

  //     points.push({ lat: lat / 1e5, lng: lng / 1e5 });
  //   }

  //   return points;
  // };

  useEffect(() => {
    const intervalId = setInterval(() => {
      // if (OrderId && orderDetail?.order_status !== "Delivered") {
      if (
        OrderId &&
        orderDetail?.order_status_id !== 10 &&
        orderDetail?.order_status_id !== 6
      ) {
        getOrderDetails("timeInterval");
      }
    }, 60000); // Set interval to 1 minute (60000ms)
    // }, 10000);

    return () => clearInterval(intervalId);
  }, [OrderId, orderDetail?.order_status]);

  const handleOpenDiler = (data) => {
    const phoneNumber = data?.driver_phone;
    if (phoneNumber) {
      // Debugging log
      console.log(`Attempting to call: ${phoneNumber}`);

      // Open the phone dialer
      window.location.href = `tel:${phoneNumber}`;
    } else {
      console.error("Driver phone number is not available");
      alert("Driver phone number is not available");
    }
  };

  useEffect(() => {
    const tawk = document.createElement("script");
    tawk.async = true;
    tawk.src = "https://embed.tawk.to/54fa63c38ac92cc0041d1a42/default";
    tawk.charset = "UTF-8";
    tawk.setAttribute("crossorigin", "*");

    document.body.appendChild(tawk);

    tawk.onload = () => {
      if (window.Tawk_API) {
        window.Tawk_API.showWidget();
      }
    };
    /* return () => {
      document.body.removeChild(tawk);
    }; */
  }, [orderDetail]);

  var DropOffIcon = {};
  var PickUpIcon = {};

  if (window.google && window.google.maps) {
    DropOffIcon = {
      url: DropOff,
      // scaledSize: new window.google.maps.Size(60, 60),
      scaledSize: new window.google.maps.Size(65, 60),
    };
    PickUpIcon = {
      url: PickUp,
      scaledSize: new window.google.maps.Size(60, 60),
    };
  }

  const handleBackButton = () => {
    if (window.Tawk_API) {
      window.Tawk_API.hideWidget();
    }
    navigate("/");
  };

  return (
    <div>
      <div className="main-container mt-0 main-container-new">
        <ReactHelmet />
        <section
          className="order-detail-sec position-relative"
          style={{ padding: "0px" }}
        >
          {!isApiLoading && !orderDetail && (
            <div className="row">
              <NotFound height={250} width={250} />
            </div>
          )}

          <button
            className="bg-white back-icon-order border-none"
            // onClick={() => navigate(-1)}
            onClick={() => handleBackButton()}
            style={{ border: "none" }}
          >
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </button>

          {orderDetail && (
            <>
              <div className="row order p-0 m-0 order-track-new">
                <div
                  className={`col-md-5 order-detail-left ${
                    bottomPanelShow ? "showPanel" : ""
                  }`}
                >
                  <div
                    className="order-head position-relative "
                    onClick={() => handleTogglePanel()}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <img src={Logo} style={{ width: "150px" }} alt="Logo" />
                      <button
                        className="bg-white me-3 d-sm-block d-none"
                        style={{ border: "none" }}
                        onClick={() => handleBackButton()}
                        // onClick={() => navigate(-1)}
                      >
                        <CloseIcon sx={{ width: 25, height: 25 }} />
                      </button>
                    </div>

                    <button
                      type="button"
                      className="d-sm-none d-block top-down-arrow bg-transparent border-0 p-0 position-absolute right-0"
                    >
                      {bottomPanelShow ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </button>
                  </div>
                  <div className="oder-contant">
                    <p className="order-content-new d-flex justify-content-center align-item-center">
                      {orderDetail?.delivery_time_message}
                    </p>
                    <p
                      className={`badge-one ${
                        orderDetail?.is_order_delivered === "2" ||
                        orderDetail?.is_order_delivered === "3"
                          ? "red-badge"
                          : ""
                      }`}
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      {orderDetail?.is_order_delivered === "1" && (
                        <img src={CheckBox} alt="Delivered" />
                      )}
                      {orderDetail?.order_status}
                    </p>

                    {/* <div className="progress-bar-track"></div> */}

                    <div className="order-address">
                      <div className="border-bottom">
                        <p className="order-lebal">Order ID</p>
                        <p className="order-text">{orderDetail?.orders_id}</p>
                      </div>
                      <div className="border-bottom mt-3 mb-3 pb-3 position-relative order-track-outer">
                        <div className="d-flex address-box address-track-new">
                          <div className="address-icon">
                            <img src={PickUp} alt="PickUp" />
                          </div>
                          <div className="address-left-wrapper">
                            <p
                              className="order-lebal"
                              style={{ fontWeight: "500", marginBottom: "0px" }}
                            >
                              Pickup Address
                            </p>
                            <p className="order-text">
                              {orderDetail?.pickup_address}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex address-box address-track-new">
                          <div className="address-icon">
                            <img src={DropOff} alt="DropOff" />
                          </div>
                          <div className="address-left-wrapper">
                            <p
                              className="order-lebal"
                              style={{ fontWeight: "500", marginBottom: "0px" }}
                            >
                              Dropoff Address
                            </p>
                            <p className="order-text mb-0">
                              {orderDetail?.dropoff_address}
                            </p>
                          </div>
                        </div>
                        {/* <div className="dot-line"></div> */}
                      </div>
                      {orderDetail?.driver_name &&
                        orderDetail?.order_status !== "Canceled" && (
                          <div className="border-bottom mt-3 mb-3 pb-3 d-flex justify-content-between align-items-center position-relative driver-info ">
                            <div className="d-flex direction-row address-box flex-column">
                              <p
                                className="order-lebal driver-font"
                                style={{
                                  fontWeight: "500",
                                  marginBottom: "0px",
                                }}
                              >
                                Driver
                              </p>
                              <p
                                style={{ marginBottom: "0px" }}
                                className="driver-font"
                              >
                                {orderDetail?.driver_name}
                              </p>

                              {/* {orderDetail?.vehicle && (
                              <p
                                className="order-lebal"
                                style={{
                                  fontWeight: "500",
                                  marginBottom: "20px",
                                }}
                              >
                                {orderDetail?.vehicle}
                              </p>
                            )} */}
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                              <div
                                className="d-flex justify-content-center mb-2 align-items-center custom-driver-box"
                                onClick={() =>
                                  setVisiblePhoneNo(!visiblePhoneNo)
                                }
                              >
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"
                                  viewBox="0 0 16 16"
                                  color="#0062FF"
                                  className="styles_phoneIcon__3kfvQ"
                                  height="30px"
                                  width="30px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ color: "rgb(0, 98, 255)" }}
                                >
                                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"></path>
                                </svg>
                              </div>
                              {visiblePhoneNo && (
                                <div
                                  onClick={() => handleOpenDiler(orderDetail)}
                                >
                                  <p
                                    style={{
                                      marginBottom: "0px",
                                      marginLeft: "10px",
                                    }}
                                    className="driver-font"
                                  >
                                    {`+1 ${orderDetail?.driver_phone}`}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                      {/* <div className="restaurnt-img-track mt-sm-5 mt-3">
                        <figure>
                          <img src={restaurantImg} alt="" />
                        </figure>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div
                  className={"col-md-7 order-detail-right d-none d-sm-block"}
                >
                  {/* <div className={"col-md-7 order-detail-right"}> */}
                  {isLoaded && (
                    <GoogleMap
                      mapContainerStyle={mapStyles}
                      zoom={14}
                      center={driverPosition || routePath[0]}
                      options={{
                        draggable: true,
                        disableDoubleClickZoom: false,
                        zoomControl: false,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        panControl: false,
                        rotateControl: false,
                        fullscreenControl: false,
                      }}
                    >
                      {/* {orderDetail?.order_status_id == 8 ||
                      orderDetail?.order_status_id == 10 ? (
                        <Polyline
                          path={routePath}
                          options={{
                            strokeColor: "#0F53FF",
                            strokeOpacity: 1,
                            strokeWeight: 5,
                          }}
                        />
                      ) : null} */}

                      {driverPosition && orderDetail?.order_status_id == 8 && (
                        <Marker position={driverPosition} icon={Driver} />
                      )}
                      {orderDetail?.order_status_id !== 6 && (
                        <Marker
                          position={routePath[0]}
                          icon={PickUpIcon}
                          // label="Pickup"
                          onMouseOver={() => setPickupHover(true)}
                          onMouseOut={() => setPickupHover(false)}
                        />
                      )}
                      {pickupHover && orderDetail?.order_status_id !== 6 && (
                        <InfoWindow
                          position={routePath[0]}
                          onCloseClick={() => setPickupHover(false)}
                          options={{
                            pixelOffset: new window.google.maps.Size(0, -40),
                          }}
                        >
                          <div
                            className="info-window-track"
                            style={{
                              background: "#fff",
                              padding: "10px",
                              borderRadius: "4px",
                              boxShadow: "0 0 14px 0 rgba(0,0,0,.37)",
                            }}
                          >
                            <p
                              style={{ fontWeight: "bold" }}
                              className="picking-track-address mb-2"
                            >
                              Pickup Address:
                            </p>
                            <p style={{ margin: 0 }}>
                              {orderDetail?.pickup_address}
                            </p>
                          </div>
                        </InfoWindow>
                      )}
                      {orderDetail?.order_status_id !== 6 && (
                        <Marker
                          position={routePath[routePath.length - 1]}
                          // icon={DropOff}
                          icon={DropOffIcon}
                          // label="Dropoff"
                          onMouseOver={() => setDropoffHover(true)}
                          onMouseOut={() => setDropoffHover(false)}
                        />
                      )}
                      {dropoffHover && orderDetail?.order_status_id !== 6 && (
                        <InfoWindow
                          position={routePath[routePath.length - 1]}
                          onCloseClick={() => setDropoffHover(false)}
                          options={{
                            pixelOffset: new window.google.maps.Size(0, -40),
                            zIndex: 1000,
                          }}
                        >
                          <div
                            className="info-window-track"
                            style={{
                              background: "#fff",
                              padding: "10px",
                              borderRadius: "4px",
                              boxShadow: "0 0 14px 0 rgba(0,0,0,.37)",
                            }}
                          >
                            <p
                              style={{ fontWeight: "bold" }}
                              className="picking-track-address mb-2"
                            >
                              Dropoff Address:
                            </p>
                            <p style={{ margin: 0 }}>
                              {orderDetail?.dropoff_address}
                            </p>
                          </div>
                        </InfoWindow>
                      )}
                    </GoogleMap>
                  )}
                </div>
              </div>

              {/** map-for-mobile */}

              <div className="d-block d-sm-none">
                {isLoaded && (
                  <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={14}
                    center={driverPosition || routePath[0]}
                    options={{
                      draggable: true,
                      disableDoubleClickZoom: false,
                      zoomControl: false,
                      mapTypeControl: false,
                      scaleControl: false,
                      streetViewControl: false,
                      panControl: false,
                      rotateControl: false,
                      fullscreenControl: false,
                    }}
                  >
                    {/* {orderDetail?.order_status_id == 8 ||
                    orderDetail?.order_status_id == 10 ? (
                      <Polyline
                        path={routePath}
                        options={{
                          strokeColor: "#0F53FF",
                          strokeOpacity: 1,
                          strokeWeight: 5,
                        }}
                      />
                    ) : null} */}
                    {driverPosition && orderDetail?.order_status_id == 8 && (
                      <Marker position={driverPosition} icon={Driver} />
                    )}
                    {orderDetail?.order_status_id !== 6 && (
                      <Marker
                        position={routePath[0]}
                        icon={PickUpIcon}
                        onClick={() =>
                          setInfoWindowOpenPickupMobile(
                            !infoWindowOpenPickupMobile
                          )
                        }
                      />
                    )}
                    {infoWindowOpenPickupMobile &&
                      orderDetail?.order_status_id !== 6 && (
                        <InfoWindow
                          position={routePath[0]}
                          onCloseClick={() =>
                            setInfoWindowOpenPickupMobile(false)
                          }
                          options={{
                            pixelOffset: new window.google.maps.Size(0, -40),
                          }}
                        >
                          <div
                            className="info-window-track"
                            style={{
                              background: "#fff",
                              padding: "10px",
                              borderRadius: "4px",
                              boxShadow: "0 0 14px 0 rgba(0,0,0,.37)",
                            }}
                          >
                            <p
                              style={{ fontWeight: "bold" }}
                              className="picking-track-address mb-2"
                            >
                              Pickup Address:
                            </p>
                            <p style={{ margin: 0 }}>
                              {orderDetail?.pickup_address}
                            </p>
                          </div>
                        </InfoWindow>
                      )}
                    {orderDetail?.order_status_id !== 6 && (
                      <Marker
                        position={routePath[routePath.length - 1]}
                        icon={DropOffIcon}
                        onClick={() =>
                          setInfoWindowOpenDropOffMobile(
                            !infoWindowOpenDropOffMobile
                          )
                        }
                      />
                    )}
                    {infoWindowOpenDropOffMobile &&
                      orderDetail?.order_status_id !== 6 && (
                        <InfoWindow
                          position={routePath[routePath.length - 1]}
                          onCloseClick={() =>
                            setInfoWindowOpenDropOffMobile(false)
                          }
                          options={{
                            pixelOffset: new window.google.maps.Size(0, -40),
                            zIndex: 1000,
                          }}
                        >
                          <div
                            className="info-window-track"
                            style={{
                              background: "#fff",
                              padding: "10px",
                              borderRadius: "4px",
                              boxShadow: "0 0 14px 0 rgba(0,0,0,.37)",
                            }}
                          >
                            <p
                              style={{ fontWeight: "bold" }}
                              className="picking-track-address mb-2"
                            >
                              Dropoff Address:
                            </p>
                            <p style={{ margin: 0 }}>
                              {orderDetail?.dropoff_address}
                            </p>
                          </div>
                        </InfoWindow>
                      )}
                    {/* {driverPosition && (
                      <Marker position={driverPosition} icon={Driver} />
                    )}

                    <Marker
                      position={routePath[0]}
                      icon={PickUp}
                    />

                    <Marker
                      position={routePath[routePath.length - 1]}
                      icon={DropOff}
                    /> */}
                  </GoogleMap>
                )}
              </div>

              {/* map-for-mobile */}
            </>
          )}
        </section>
      </div>
    </div>
  );
};

export default OrderTrackNew;
